import { Pipe, PipeTransform } from '@angular/core';
import { Patient } from 'src/API';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
  transform(patient: Patient): any {
    let fullAddress = '';
    if (patient.address) {
      const { address, city, province, country, postalCode } = patient;
      let isFirst = true;
      [address, city, province, country, postalCode]
        .filter(item => !!item)
        .forEach(item => {
          fullAddress += (isFirst ? '' : ', ') + item;
          isFirst = false;
        });
    }
    return fullAddress;
  }
}
