import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionnaireRequest } from 'src/API';

@Injectable({
  providedIn: 'root'
})
export class DerfsService {
  public nonModifiableData = {
    eyelashModification: 'yes',
    eyelashModificationBrands: 'asd',
    orthokeratology: true,
    lasikOrPRK: true,
    ethnicity: 'others',
    retinalSurgery: true,
    autoImmuneDiseaseHistory: true,
    female: true,
    rosacea: true,
    ethnicityOtherValue: 'dfg',
    pelvicPain: true,
    sickBuildingSyndrome: 'yes',
    sarcoidosis: true,
    irritableBowelSyndrome: true,
    hepatitis: true,
    hematopoieticStemCellTransplantation: 'yes',
    cancerHistory: true,
    thyroid: true,
    thyroidValue: 'hi',
    cataractSurgery: true,
    psoriasis: true,
    ptosisSurgery: true,
    rosaceaHistory: true,
    strabismusSurgery: true,
    nonBinary: true,
    botoxInjectionsToFace: 'yes',
    male: true,
    childBirth: 'yes',
    education: 'university',
    eyeSurgeryPerformed: 'yes',
    styeOrChalazion: 'once',
    autoImmuneConditions: 'yes',
    autoImmuneConditionsValue: 'asdas'
  };

  questionsMap = {
    eyeProblems: 'In your own words, please describe what problems you are having with your eyes:',

    ethnicityGroup: 'To which ethnic group do you belong?',

    genderQuestion: 'Which of the following best describes you? (Select all that apply)',

    smokingQuestion: 'Do you smoke, vape, or have done either in the last 6 months?',

    contactLenses: 'Do you wear contact lenses?',

    faceCareAndMakeup: 'Do you use face care or make-up products?',

    productAndMakeupIrritation: 'Do skin products or eye-make up irritate your eyes?',

    eyelashSerumOrExtensions: 'Have you ever used eyelash growth serum or eyelash extensions?',

    botoxInjections: 'Have you had Botox injections to your face in the past?',

    foodSwallowingAndDryMouth:
      'Do you experience difficulty swallowing food and/or dryness in your mouth?',

    lastEyeExam: 'When was your last general eye exam?',

    alcoholConsumption: 'Do you consume beverages containing alcohol?',

    beveargeConsumption: 'Do you drink the following beverages?',

    nutritionalSupplementsConsumption:
      'Are you currently taking any nutritional supplements (e.g. vitamins, minerals), or have you taken any in the last 6 months?',

    dietType: 'Which of the followings describes you? (Select all that apply)',

    foodIntake: 'In a typical day, the balance of my food intake is... (select all that apply)',

    urbanAreaTimeDuration:
      'What percentage of your time per week do you spend in urban areas (city/town with more than 2500 people)?',

    highestLevelOfEducation: 'What is the highest level of education you have completed?',

    screenTimeDuration:
      'Please estimate the average time you have spent using screen-based digital devices on a typical day in the past month:',

    sleepDuration: 'On average, how much sleep do you get per night?',

    sleepPosition: 'Which position do you normally sleep in?',

    outdoorTimeDuration: 'On average, how much time do you spend outdoors?',

    activityOverWeek: 'Over the last week, how often have you…',

    airconTimeDuration: 'On average, how much time do you spend in…',

    workTimeDuration: 'How many hours do you typically work in a day?',

    symptomFrequency:
      'Do you frequently experience any of the following symptoms at work or home? (Select all that apply)',

    cityHumidityLevel: "What is your city's typical humidity level?",

    cityPollutionLevel: "What is your city's typical pollution level?",

    dailyStressLevel: 'How stressful do you feel your days are?',

    currentOrPastCondition:
      'Do you have any of the following conditions, or have had them in the past? (Select all that apply)',

    dryEyeTreatmentsHistory:
      'Have you tried any of the following dry eye treatments? (Select all that apply)',

    eyeSurgeryHistory: 'Have you had any eye surgery?',

    currentOrPastMedications:
      ' Are you taking any medications, or have been taking any in the last 6 months?',

    eyeHealthHistory: 'Please select all that applies to your eye health history:',

    familyHistoryConditions: 'Do you have a family history of any of the following:',

    symptomsSeverity: 'Please rate your following symptoms on a scale of 0 to 10:',

    styeHistory: 'Have you ever had a stye? A chalazion?',

    currentSymptoms: 'Do you currently have any of the following symptoms? (Select all that apply)',

    currentEyeMeds: 'Are you currently taking any eye medications and drops?',

    pastEyeMeds: 'Have you taken any eye medications and drops in the past?',

    historyOfHSCT: 'Have you had hematopoietic stem cell transplantation (HSCT)?',

    historyOfSBS: 'Have you been diagnosed with sick building syndrome (SBS) in the past?',

    autoimmuneConditions: 'Do you have any auto-immune conditions?',

    qualityOfHealthRating: 'Please rate the quality of your health:',

    qualityOfDietRating: 'Please rate the quality of your diet:',

    eyeItchinessRating: 'Please rate the severity of your eye itchiness:',

    wateryEyesCheck: 'Do you experience watery eyes?',

    eyeDrynessOrIrritation: 'Do you experience eye dryness and/or eye irritability?',

    leftOrRightEyeIrritation: 'Which eye bothers you?',

    eyeRubbingFrequency: 'How often do you rub your eyes?'
  };
  conditionsList = {
    anxiety: {
      description: 'Anxiety',
      mapsTo: ['anxiety'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    migraine: {
      description: 'Migraine',
      mapsTo: ['migraine'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    asthma: {
      description: 'Asthma',
      mapsTo: ['asthma'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    seasonalAllergies: {
      description: 'Seasonal allergies',
      inputType: 'text',
      label: 'Please describe',
      toggleControlList: [['seasonalAllergiesValue']],
      toggleConditions: [true],
      toggledControlRequired: true,
      mapsTo: ['seasonalAllergies'],
      mapConditions: [''],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: false,
        nonModifiableConditions: []
      }
    },
    drugAllergies: {
      description: 'Drug allergies',
      inputType: 'text',
      label: 'Please describe',
      toggleControlList: [['drugAllergiesValue']],
      toggleConditions: [true],
      toggledControlRequired: true,
      mapsTo: ['drugAllergies'],
      mapConditions: [''],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: false,
        nonModifiableConditions: []
      }
    },

    hypertension: {
      description: 'Hypertension',
      mapsTo: ['hypertension'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    glaucoma: {
      description: 'Glaucoma',
      mapsTo: ['glaucoma'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    diabetes: {
      description: 'Diabetes',
      mapsTo: ['diabetes'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    hypercholesterolemia: {
      description: 'Hypercholesterolemia',
      mapsTo: ['hypercholesterolemia'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    eczema: {
      description: 'Eczema',
      mapsTo: ['eczema'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    acne: {
      description: 'Acne',
      mapsTo: ['acne'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    rosacea: {
      description: 'Rosacea',
      mapsTo: ['rosacea'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    psoriasis: {
      description: 'Psoriasis',
      mapsTo: ['psoriasis'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    lupus: {
      description: 'Lupus',
      mapsTo: ['lupus'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    arthritis: {
      description: 'Arthritis',
      mapsTo: ['arthritis'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    osteoporosis: {
      description: 'Osteoporosis',
      mapsTo: ['osteoporosis'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    sclerosis: {
      description: 'Sclerosis',
      mapsTo: ['sclerosis'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    thyroid: {
      description: 'Thyroid disease',
      label: 'Please describe',
      inputType: 'text',
      toggleControlList: [['thyroidValue']],
      toggleConditions: [true],
      mapsTo: ['thyroid', 'thyroidConditionFreeText'],
      mapConditions: [true, ''],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    chronic: {
      description: 'Chronic widespread pain syndrome',
      label: 'Please specify',
      inputType: 'text',
      toggleControlList: [['chronicValue']],
      toggleConditions: [true],
      mapsTo: ['chronic', 'chronicPainConditionFreeText'],
      mapConditions: [true, ''],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    hepatitis: {
      description: 'Hepatitis C',
      mapsTo: ['hepatitis'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    hiv: {
      description: 'Human immunodeficiency virus (HIV)',
      mapsTo: ['hiv'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    htlv: {
      description: 'Human T-cell lymphotropic virus-1 (HTLV-1)',
      mapsTo: ['htlv'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    stroke: {
      description: 'Stroke',
      mapsTo: ['stroke'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    cancer: {
      description: 'Cancer',
      mapsTo: ['cancer'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    vitaminDeficiency: {
      description: 'Vitamin deficiency',
      label: 'Specify',
      inputType: 'text',
      toggleControlList: [['vitaminDeficiencyValue']],
      toggleConditions: [true],
      mapsTo: ['vitaminDeficiency', 'vitaminDeficiencyConditionFreeText'],
      mapConditions: [true, ''],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: false,
        nonModifiableConditions: []
      }
    },
    sexSteroidDeficiency: {
      description: 'Sex steroid deficiency',
      label: 'Please describe',
      inputType: 'text',
      toggleControlList: [['sexSteroidDeficiencyValue']],
      toggleConditions: [true],
      mapsTo: ['sexSteroidDeficiency', 'sexSteroidDeficiencyConditionFreeText'],
      mapConditions: [true, ''],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: false,
        nonModifiableConditions: []
      }
    },
    gout: {
      description: 'Gout',
      mapsTo: ['gout'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    vitiligo: {
      description: 'Vitiligo',
      mapsTo: ['vitiligo'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    liverDisease: {
      description: 'Liver disease',
      mapsTo: ['liverDisease'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    renalFailure: {
      description: 'Renal failure',
      mapsTo: ['renalFailure'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    sjorgenSyndrome: {
      description: 'Sjögren syndrome',
      mapsTo: ['sjorgenSyndrome'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    radiotherapy: {
      description: 'Radiotherapy',
      mapsTo: ['radiotherapy'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    boneMarrow: {
      description: 'Bone marrow transplantation',
      mapsTo: ['boneMarrow'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    irritableBowelSyndrome: {
      description: 'Irritable bowel syndrome (IBS)',
      mapsTo: ['irritableBowelSyndrome'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    polycysticOvary: {
      description: 'Polycystic ovary syndrome',
      mapsTo: ['polycysticOvary'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    fertility: {
      description: 'Fertility problems',
      mapsTo: ['fertility'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    pelvicPain: {
      description: 'Pelvic pain',
      mapsTo: ['pelvicPain'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    psychiatricConditions: {
      description: 'Psychiatric conditions (e.g. depression, bipolar, schizophrenia)',
      inputType: 'text',
      label: 'Please describe',
      toggleControlList: [['psychiatricConditionsValue']],
      toggleConditions: [true],
      mapsTo: ['psychiatricConditions', 'psychiatricConditionsFreeText'],
      mapConditions: [true, ''],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    demodexSkinInfection: {
      description: 'Demodex skin infection',
      mapsTo: ['demodexSkinInfection'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    androgenDeficiency: {
      description: 'Androgen deficiency',
      mapsTo: ['androgenDeficiency'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    sarcoidosis: {
      description: 'Sarcoidosis',
      mapsTo: ['sarcoidosis'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    accutaneTreatment: {
      description: 'Treatment with Accutane',
      mapsTo: ['accutaneTreatment'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    dandruff: {
      description: 'Dandruff',
      mapsTo: ['dandruff'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    scleroderma: {
      description: 'Scleroderma',
      mapsTo: ['scleroderma'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    seborrheicDermatitis: {
      description: 'Seborrheic dermatitis',
      mapsTo: ['seborrheicDermatitis'],
      mapConditions: [true],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    conditionOther: {
      description: 'Other',
      label: 'Please describe',
      inputType: 'text',
      toggleControlList: [['conditionOtherValue']],
      toggleConditions: [true],
      mapsTo: ['conditionOther', 'medicalConditionsFreeText'],
      mapConditions: [true, ''],
      patientFileMapConfig: [{ mapsTo: 'medicalConditions', mapCondition: true }],
      nonModifiableConfig: {
        nonModifiable: false,
        nonModifiableConditions: []
      }
    }
  };

  medicationsList = {
    antidepressents: {
      description: 'Antidepressants',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['antidepressentsValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\n\nAntidepressents:\n' + derfsVal
          : 'Antidepressents:\n' + derfsVal
    },
    antihistamineDrugs: {
      description: 'Antihistamine drugs',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['antihistamineDrugsValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\n\nAntihistamine drugs:\n' + derfsVal
          : 'Antihistamine drugs:\n' + derfsVal
    },
    antihistamineEyeDrops: {
      description: 'Antihistamine eye drops',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['antihistamineEyeDropsValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\n\nAntihistamine eye drops:\n' + derfsVal
          : 'Antihistamine eye drops:\n' + derfsVal
    },
    bloodPressurePills: {
      description: 'Blood pressure pills',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['bloodPressurePillsValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\n\nBlood pressure pills:\n' + derfsVal
          : 'Blood pressure pills:\n' + derfsVal
    },
    diuretics: {
      description: 'Diuretics (water pills for hypertension)',
      inputType: 'text',
      label: 'Medication(s)',
      toggleControlList: [['diureticsValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\n\nDiuretics (water pills for hypertension):\n' + derfsVal
          : 'Diuretics (water pills for hypertension):\n' + derfsVal
    },
    glaucomaDrops: {
      description: 'Glaucoma eye drops',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['glaucomaDropsValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\n\nGlaucoma eye drops:\n' + derfsVal
          : 'Glaucoma eye drops:\n' + derfsVal
    },
    hormoneTherapy: {
      description: 'Hormone therapy',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['hormoneTherapyValue']],
      toggleConditions: [true],
      mapsTo: ['hormoneReplacementTherapy', 'patientEnteredMedicationsFreeText'],
      mapConditions: [true, ''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\n\nHormone therapy:\n' + derfsVal
          : 'Hormone therapy:\n' + derfsVal
    },
    medicationOther: {
      description: 'Other or unknown',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['medicationOtherValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\n\nOther or unknown:\n' + derfsVal
          : 'Other or unknown:\n' + derfsVal
    },
    oralContraceptives: {
      description: 'Oral Contraceptives',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['oralContraceptivesValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\n\nOral Contraceptives:\n' + derfsVal
          : 'Oral Contraceptives:\n' + derfsVal
    },
    oralSteroids: {
      description: 'Oral steroids',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['oralSteroidsValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal ? currentVal + '\n\nOral steroids:\n' + derfsVal : 'Oral steroids:\n' + derfsVal
    },
    pillsForSkin: {
      description: 'Oral medication for skin problems or disorders',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['pillsForSkinValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\n\nOral medication for skin problems or disorders:\n' + derfsVal
          : 'Oral medication for skin problems or disorders:\n' + derfsVal
    },
    sleepingTablets: {
      description: 'Sleeping tablets',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['sleepingTabletsValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\n\nSleeping tablets:\n' + derfsVal
          : 'Sleeping tablets:\n' + derfsVal
    },
    tranquilizers: {
      description: 'Tranquilizers',
      label: 'Medication(s)',
      inputType: 'text',
      toggleControlList: [['tranquilizersValue']],
      toggleConditions: [true],
      mapsTo: ['patientEnteredMedicationsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal ? currentVal + '\n\nTranquilizers:\n' + derfsVal : 'Tranquilizers:\n' + derfsVal
    }
  };

  surgeriesList = {
    glaucomaSurgery: {
      description: 'Glaucoma surgery',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal ? currentVal + '\nGlaucoma surgery' : 'Glaucoma surgery'
    },
    cataractSurgery: {
      description: 'Cataract surgery',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal ? currentVal + '\nCataract surgery' : 'Cataract surgery'
    },
    strabismusSurgery: {
      description: 'Strabismus surgery',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal ? currentVal + '\nStrabismus surgery' : 'Strabismus surgery'
    },
    ptosisSurgery: {
      description: 'Ptosis surgery',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal ? currentVal + '\nPtosis surgery' : 'Ptosis surgery'
    },
    retinalSurgery: {
      description: 'Retinal surgery',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal ? currentVal + '\nRetinal surgery' : 'Retinal surgery'
    },
    refractiveSurgery: {
      description: 'Refractive surgery (prk/lasik)',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\nRefractive surgery (prk/lasik)'
          : 'Refractive surgery (prk/lasik)'
    },
    otherSurgery: {
      description: 'Other',
      label: 'Please describe',
      inputType: 'text',
      required: true,
      toggleControlList: [['otherSurgeryValue']],
      toggleConditions: [true],
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [''],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal ? currentVal + '\n' + derfsVal : '\n' + derfsVal
    }
  };

  dietList = {
    // tslint:disable-next-line:quotemark
    vegan: {
      description:
        'I am a vegan – I do not eat meat, fish, dairy, or other products derived from animals',
      mapsTo: ['dietFreeText'],
      mapConditions: [true],
      transformValue: (derfsVal, currentVal) =>
        (currentVal || '') + '\n' + this.dietList.vegan.description
    },
    completeVegetarian: {
      description: 'I am completely vegetarian',
      mapsTo: ['dietFreeText'],
      mapConditions: [true],
      transformValue: (derfsVal, currentVal) =>
        (currentVal || '') + '\n' + this.dietList.completeVegetarian.description
    },
    partialVegetarian: {
      description: 'I am vegetarian, but eat... (describe)',
      label: 'Please describe',
      inputType: 'text',
      toggleControlList: [['partialVegetarianValue']],
      toggleConditions: [true],
      mapsTo: ['dietFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        (currentVal || '') +
        '\n' +
        this.dietList.partialVegetarian.description +
        '\n    ' +
        derfsVal
    },
    omnivorous: {
      description: 'I am omnivorous – I eat products derived from animals and plants',
      mapsTo: ['dietFreeText'],
      mapConditions: [true],
      transformValue: (derfsVal, currentVal) =>
        (currentVal || '') + '\n' + this.dietList.omnivorous.description
    },
    oilyFish: {
      description: 'I eat regularly oily fish (at least 2 or 3 times per week)',
      mapsTo: ['dietFreeText'],
      mapConditions: [true],
      transformValue: (derfsVal, currentVal) =>
        (currentVal || '') + '\n' + this.dietList.oilyFish.description
    },
    loseWeight: {
      description: 'I am on diet to lose weight/special diet',
      label: 'Please describe',
      inputType: 'text',
      toggleControlList: [['loseWeightValue']],
      toggleConditions: [true],
      mapsTo: ['dietFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        (currentVal || '') + '\n' + this.dietList.loseWeight.description + '\n    Diet: ' + derfsVal
    }
  };

  foodIntakeList = {
    animalsAndPlants: {
      description: 'directly obtained from animals or plants with minimal processing',
      inputType: 'number',
      label: 'percentage (%)',
      note:
        // tslint:disable-next-line:quotemark
        "e.g. fruits, vegetables, seeds without salt, 'long-life' and powdered milk, yoghurt without sugar, dried or fresh pasta, fresh/frozen/dried meat and fish...",
      toggleControlList: [['animalsAndPlantsValue']],
      toggleConditions: [true],
      mapsTo: ['dietFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) => {
        return (
          (currentVal || '') +
          '\n' +
          derfsVal +
          '% of my food intake is ' +
          this.foodIntakeList.animalsAndPlants.description
        );
      }
    },
    naturalFoods: {
      description: 'substances extracted from natural foods',
      inputType: 'number',
      label: 'percentage (%)',
      note: 'e.g. plant oils, honey, sugar, salt, butter...',
      toggleControlList: [['naturalFoodsValue']],
      toggleConditions: [true],
      mapsTo: ['dietFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) => {
        return (
          (currentVal || '') +
          '\n' +
          derfsVal +
          '% of my food intake is ' +
          this.foodIntakeList.naturalFoods.description
        );
      }
    },
    readyToEat: {
      description: 'ready-to-eat foods',
      inputType: 'number',
      label: 'percentage (%)',
      note:
        'e.g. ham, cheese, bacon, canned sardine, canned tuna, canned/bottled vegetables, legumes or fruits...',
      toggleControlList: [['readyToEatValue']],
      toggleConditions: [true],
      mapsTo: ['dietFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) => {
        return (
          (currentVal || '') +
          '\n' +
          derfsVal +
          '% of my food intake is ' +
          this.foodIntakeList.readyToEat.description
        );
      }
    },
    ultraProcessed: {
      description: 'ultra-processed',
      inputType: 'number',
      label: 'percentage (%)',
      note:
        'e.g. potato fries, potato chips, potato crisps, sweets, fatty or salty snacks, cakes, noodles, burgers, hot dogs, sauces, soft or energy drinks, pizza, pasta...',
      toggleControlList: [['ultraProcessedValue']],
      toggleConditions: [true],
      mapsTo: ['dietFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) => {
        return (
          (currentVal || '') +
          '\n' +
          derfsVal +
          '% of my food intake is ' +
          this.foodIntakeList.ultraProcessed.description
        );
      }
    }
  };

  deviceList = {
    smartphone: {
      description: 'Smartphone, iPhone',
      inputType: 'slider',
      label: 'Hours per Day',
      min: 1,
      max: 12,
      step: 1,
      default: -1,
      toggleControlList: [['smartphoneValue']],
      toggleConditions: [true],
      mapsTo: ['screenTime'],
      mapConditions: [1],
      transformValue: (derfsVal, currentVal?) => (currentVal ? currentVal + derfsVal : derfsVal)
    },
    tablet: {
      description: 'Tablet, iPad, Kindle',
      inputType: 'slider',
      label: 'Hours per Day',
      min: 1,
      max: 12,
      step: 1,
      default: -1,
      toggleControlList: [['tabletValue']],
      toggleConditions: [true],
      mapsTo: ['screenTime'],
      mapConditions: [1],
      transformValue: (derfsVal, currentVal?) => (currentVal ? currentVal + derfsVal : derfsVal)
    },
    laptop: {
      description: 'Laptop, Notebook',
      inputType: 'slider',
      label: 'Hours per Day',
      min: 1,
      max: 12,
      step: 1,
      default: -1,
      toggleControlList: [['laptopValue']],
      toggleConditions: [true],
      mapsTo: ['screenTime'],
      mapConditions: [1],
      transformValue: (derfsVal, currentVal?) => (currentVal ? currentVal + derfsVal : derfsVal)
    },
    desktop: {
      description: 'Desktop Computer',
      inputType: 'slider',
      label: 'Hours per Day',
      min: 1,
      max: 12,
      step: 1,
      default: -1,
      toggleControlList: [['desktopValue']],
      toggleConditions: [true],
      mapsTo: ['screenTime', 'screenTime'],
      mapConditions: [false, ['']],
      transformValue: (derfsVal, currentVal?) => {
        if (currentVal) {
          currentVal = currentVal + derfsVal;
        } else {
          currentVal = derfsVal;
        }
        if (currentVal < 1) return 'group1';
        else if (currentVal < 3) return 'group2';
        else if (currentVal <= 6) return 'group3';
        else return 'group4';
      }
    }
  };

  activityList = {
    work: {
      description: 'Work, study, reading',
      inputType: 'slider',
      label: 'Hours per Day',
      min: 1,
      max: 12,
      step: 1,
      default: -1,
      toggleControlList: [['workValue']],
      toggleConditions: [true]
    },
    socialMedia: {
      description: 'Social media, browsing, TV, streaming video, movies',
      inputType: 'slider',
      label: 'Hours per Day',
      min: 1,
      max: 12,
      step: 1,
      default: -1,
      toggleControlList: [['socialMediaValue']],
      toggleConditions: [true]
    },
    email: {
      description: 'Email, texting, messaging',
      inputType: 'slider',
      label: 'Hours per Day',
      min: 1,
      max: 12,
      step: 1,
      default: -1,
      toggleControlList: [['emailValue']],
      toggleConditions: [true]
    },
    gaming: {
      description: 'Gaming',
      inputType: 'slider',
      label: 'Hours per Day',
      min: 1,
      max: 12,
      step: 1,
      default: -1,
      toggleControlList: [['gamingValue']],
      toggleConditions: [true]
    }
  };

  genderList = {
    male: {
      description: 'Male',
      patientFileMapConfig: [
        {
          mapsTo: 'gender',
          mapCondition: true
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    female: {
      description: 'Female',
      toggleControlList: [['menstruation', 'childBirth']],
      toggleConditions: [true],
      patientFileMapConfig: [
        {
          mapsTo: 'gender',
          mapCondition: true
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    transgender: {
      description: 'Transgender',
      patientFileMapConfig: [
        {
          mapsTo: 'gender',
          mapCondition: true
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    nonBinary: {
      description: 'Non-binary/Non-conforming',
      patientFileMapConfig: [
        {
          mapsTo: 'gender',
          mapCondition: true
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    intersex: {
      description: 'Intersex',
      patientFileMapConfig: [
        {
          mapsTo: 'gender',
          mapCondition: true
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    genderOther: {
      description: 'Prefer to describe',
      inputType: 'text',
      label: 'Please describe',
      toggleControlList: [['genderOtherValue']],
      toggleConditions: [true],
      patientFileMapConfig: [
        {
          mapsTo: 'gender',
          mapCondition: true
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    preferNotToSay: {
      description: 'Prefer not to say',
      patientFileMapConfig: [
        {
          mapsTo: 'gender',
          mapCondition: true
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    }
  };

  pastOcularHistoryList = {
    wearsGlasses: {
      description: 'I wear glasses',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      transformValue: (derfsVal, currentVal) =>
        currentVal ? currentVal + '\nI wear glasses' : 'I wear glasses',
      nonModifiableConfig: {
        nonModifiable: false,
        nonModifiableConditions: []
      }
    },
    pastContactLensDiscomfort: {
      description: 'I have contact lens discomfort or intolerance',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\nI have contact lens discomfort or intolerance'
          : '\nI have contact lens discomfort or intolerance',
      nonModifiableConfig: {
        nonModifiable: false,
        nonModifiableConditions: []
      }
    },
    lasikOrPRK: {
      description: 'I had Lasik or PRK',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal ? currentVal + '\nI had Lasik or PRK' : 'I had Lasik or PRK'
    },
    orthokeratology: {
      description: 'I had orthokeratology',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal ? currentVal + '\nI had orthokeratology' : 'I had orthokeratology'
    },
    myopia: {
      description:
        'I have myopia (near-sightedness), or I need prescription lenses to see distant objects',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal +
            '\nI have myopia (near-sightedness), or I need prescription lenses to see distant objects'
          : 'I have myopia (near-sightedness), or I need prescription lenses to see distant objects'
    },
    hyperopia: {
      description:
        'I have hyperopia (far-sightedness), or I see distant objects more clearly than nearby objects',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal +
            '\nI have hyperopia (far-sightedness), or I see distant objects more clearly than nearby objects'
          : 'I have hyperopia (far-sightedness), or I see distant objects more clearly than nearby objects'
    },
    presbyopia: {
      description: 'I have presbyopia, or I cannot see the computer screen without reading glasses',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal +
            '\nI have presbyopia, or I cannot see the computer screen without reading glasses'
          : 'I have presbyopia, or I cannot see the computer screen without reading glasses'
    },
    amblyopia: {
      description: 'I have amblyopia, or lazy eye”',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\nI have amblyopia, or lazy eye'
          : 'I have amblyopia, or lazy eye”'
    },
    strabismus: {
      description: 'I have or had strabismus, or my eyes are not aligned',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\nI have or had strabismus, or my eyes are not aligned'
          : 'I have or had strabismus, or my eyes are not aligned'
    },
    eyeTrauma: {
      description: 'I had trauma or injury to the eyes',
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? currentVal + '\nI had trauma or injury to the eyes'
          : 'I had trauma or injury to the eyes'
    },
    pterygium: {
      // tslint:disable-next-line:quotemark
      description: "I have pterygium, or surfer's Eye",
      mapsTo: ['previousOcularSurgeryFreeText'],
      mapConditions: [true],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      },
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? // tslint:disable-next-line:quotemark
            currentVal + "\nI have pterygium, or surfer's Eye"
          : // tslint:disable-next-line:quotemark
            "I have pterygium, or surfer's Eye"
    },

    otherPastOcularHistory: {
      description: 'Other',
      toggleControlList: [['otherPastOcularHistoryValue']],
      toggleConditions: [true],
      inputType: 'text',
      label: 'Please describe',
      mapsTo: ['previousOcularSurgeryFreeText'],
      nonModifiableConfig: {
        nonModifiable: false,
        nonModifiableConditions: []
      },
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) =>
        currentVal
          ? // tslint:disable-next-line:quotemark
            currentVal + '\n' + derfsVal
          : // tslint:disable-next-line:quotemark
            derfsVal
    }
  };

  familyHistoryConditionsList = {
    autoImmuneDiseaseHistory: {
      description: 'Autoimmune disease',
      patientFileMapConfig: [
        {
          mapsTo: 'familyHistory',
          mapCondition: true,
          mapValue: 'Autoimmune disease'
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    cancerHistory: {
      description: 'Cancer',
      patientFileMapConfig: [
        {
          mapsTo: 'familyHistory',
          mapCondition: true,
          mapValue: 'Cancer'
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    cataractsHistory: {
      description: 'Cataracts',
      patientFileMapConfig: [
        {
          mapsTo: 'familyHistory',
          mapCondition: true,
          mapValue: 'Cataracts'
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    retinalDetachmentHistory: {
      description: 'Detached retina',
      patientFileMapConfig: [
        {
          mapsTo: 'familyHistory',
          mapCondition: true,
          mapValue: 'Detached retina'
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    diabetesHistory: {
      description: 'Diabetes',
      patientFileMapConfig: [
        {
          mapsTo: 'familyHistory',
          mapCondition: true,
          mapValue: 'Diabetes'
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    glaucomaHistory: {
      description: 'Glaucoma',
      patientFileMapConfig: [
        {
          mapsTo: 'familyHistory',
          mapCondition: true,
          mapValue: 'Glaucoma'
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    highBloodPressureHistory: {
      description: 'High blood pressure',
      patientFileMapConfig: [
        {
          mapsTo: 'familyHistory',
          mapCondition: true,
          mapValue: 'High blood pressure'
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },
    highCholestrolHistory: {
      description: 'High cholesterol',
      patientFileMapConfig: [
        {
          mapsTo: 'familyHistory',
          mapCondition: true,
          mapValue: 'High cholesterol'
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    },

    rosaceaHistory: {
      description: 'Rosacea',
      patientFileMapConfig: [
        {
          mapsTo: 'familyHistory',
          mapCondition: true,
          mapValue: 'Rosacea'
        }
      ],
      nonModifiableConfig: {
        nonModifiable: true,
        nonModifiableConditions: [true]
      }
    }
  };

  eyeSymptomsList = {
    currentContactLensDiscomfort: 'Contact lens discomfort',
    crustyOrDischarge: {
      description: 'Crusty eyes or eye discharge',
      mapsTo: ['crustyOrDischarge'],
      mapConditions: [true]
    },
    eyeDryness: 'Eye dryness',
    eyeFatigue: 'Eye fatigue',
    itching: 'Eye itching',
    eyeRedness: 'Eye redness',

    fluctuatingVision: {
      description: 'Fluctuating vision',
      mapsTo: ['visionAffected'],
      mapConditions: [true]
    },

    enlargedGlands: {
      description: 'Enlarged glands',
      mapsTo: ['enlargedGlands'],
      mapConditions: [true]
    },

    eyeMucous: 'Stringy mucous in or around eyes',

    swollenEyelids: {
      description: 'Swollen eyelids',
      mapsTo: ['eyesSwollen'],
      mapConditions: [true]
    },

    rednessGreaterInEye: {
      description: 'Symptoms/redness greater in one eye',
      mapsTo: ['symptomsRednessGreaterInOneEye'],
      mapConditions: [true]
    }
  };

  environmentalSymptomsList = {
    allergySymptoms: {
      description: 'Allergy-like symptoms, such as sneezing',
      mapsTo: ['allergySymptoms'],
      mapConditions: [true]
    },
    bodyAches: { description: 'Body aches', mapsTo: ['bodyAches'], mapConditions: [true] },
    breathingDifficulty: {
      description: 'Breathing difficulties',
      mapsTo: ['breathingDifficulty'],
      mapConditions: [true]
    },
    noseBurningSensation: {
      description: 'Burning sensation in the nose',
      mapsTo: ['noseBurningSensation'],
      mapConditions: [true]
    },
    chills: { description: 'Chills', mapsTo: ['chills'], mapConditions: [true] },
    difficultyConcentrating: {
      description: 'Difficulty concentrating',
      mapsTo: ['difficultyConcentrating'],
      mapConditions: [true]
    },
    dizziness: { description: 'Dizziness', mapsTo: ['dizziness'], mapConditions: [true] },
    skinDrynessAndItchiness: {
      description: 'Dry, itchy skin, rashes',
      mapsTo: ['skinDrynessAndItchiness'],
      mapConditions: [true]
    },

    fatigue: { description: 'Fatigue', mapsTo: ['fatigue'], mapConditions: [true] },
    fever: { description: 'Fever', mapsTo: ['fever'], mapConditions: [true] },
    forgetfulness: {
      description: 'Forgetfulness',
      mapsTo: ['forgetfulness'],
      mapConditions: [true]
    },
    headaches: { description: 'Headaches', mapsTo: ['headaches'], mapConditions: [true] },
    irritability: {
      description: 'Irritability',
      mapsTo: ['irritability'],
      mapConditions: [true]
    },
    nausea: { description: 'Nausea', mapsTo: ['nausea'], mapConditions: [true] },

    runnyNose: { description: 'Runny nose', mapsTo: ['runnyNose'], mapConditions: [true] },

    throatIrritation: {
      description: 'Throat irritation',
      mapsTo: ['throatIrritation'],
      mapConditions: [true]
    },
    chestTightness: {
      description: 'Tightness in the chest',
      mapsTo: ['chestTightness'],
      mapConditions: [true]
    }
  };

  pastDryEyeTreatmentsList = {
    artificialTears: {
      description: 'Artificial Tears',
      inputType: 'text',
      label: 'Brand / Times per day / Duration / Last used',
      toggleControlList: [['artificialTearsValue']],
      toggleConditions: [true],
      mapsTo: ['pastDryEyeTreatmentsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) => {
        return currentVal
          ? currentVal + '\n\nArtificial Tears:\n' + derfsVal
          : 'Artificial Tears:\n' + derfsVal;
      }
    },
    inOfficeTreatments: {
      description:
        'In office treatments such as intense pulsed light, Lipiflow, iLux, low level light therapy, and radiotherapy',
      inputType: 'text',
      label: 'Type / Number of treatments / Last treatment',
      toggleControlList: [['inOfficeTreatmentsValue']],
      toggleConditions: [true],
      mapsTo: ['pastDryEyeTreatmentsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) => {
        return currentVal
          ? currentVal +
              '\n\nIn office treatments such as intense pulsed light, Lipiflow, iLux, low level light therapy, and radiotherapy:\n' +
              derfsVal
          : 'In office treatments such as intense pulsed light, Lipiflow, iLux, low level light therapy, and radiotherapy:\n' +
              derfsVal;
      }
    },
    lidScrubs: {
      description: 'Lid scrubs / Lid hygiene',
      inputType: 'text',
      label: 'Brand / Times per day / Duration / Last used',
      toggleControlList: [['lidScrubsValue']],
      toggleConditions: [true],
      mapsTo: ['pastDryEyeTreatmentsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) => {
        return currentVal
          ? currentVal + '\n\nLid scrubs / Lid hygiene:\n' + derfsVal
          : 'Lid scrubs / Lid hygiene:\n' + derfsVal;
      }
    },
    pharmaceuticals: {
      description: 'Pharmaceuticals',
      inputType: 'text',
      label: 'Brand / Times per day / Duration / Last used',
      toggleControlList: [['pharmaceuticalsValue']],
      toggleConditions: [true],
      mapsTo: ['pastDryEyeTreatmentsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) => {
        return currentVal
          ? currentVal + '\n\nPharmaceuticals:\n' + derfsVal
          : 'Pharmaceuticals:\n' + derfsVal;
      }
    },
    warmCompresses: {
      description: 'Warm Compresses',
      inputType: 'text',
      label: 'Brand / Times per day / Duration / Last used',
      toggleControlList: [['warmCompressesValue']],
      toggleConditions: [true],
      mapsTo: ['pastDryEyeTreatmentsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) => {
        return currentVal
          ? currentVal + '\n\nWarm Compresses:\n' + derfsVal
          : 'Warm Compresses:\n' + derfsVal;
      }
    },

    otherPastDryEyeTreatments: {
      description: 'Other',
      inputType: 'text',
      label: 'Please describe',
      toggleControlList: [['otherPastDryEyeTreatmentsValue']],
      toggleConditions: [true, ''],
      mapsTo: ['pastDryEyeTreatmentsFreeText'],
      mapConditions: [''],
      transformValue: (derfsVal, currentVal) => {
        return currentVal ? currentVal + '\n\nOther:\n' + derfsVal : 'Other:\n' + derfsVal;
      }
    }
  };

  // **************************** Radio Maps ****************************

  ethnicityRadioMap = {
    white: 'White',
    black: 'Black',
    'south-asian': 'South asian (e.g., Indian/Pakistani)',
    'east-asian': 'East asian (e.g., Chinese/Malay)',
    hispanic: 'Hispanic',
    others: 'Other'
  };

  educationRadioMap = {
    elementary: 'Elementary/Primary School',
    middle: 'Middle/Secondary School',
    highschool: 'Highschool/6th Form',
    university: 'University or higher'
  };

  menstruationRadioMap = {
    menopause: 'Menopause',
    regular: 'Regular menstruation',
    irregular: 'Irregular menstruation',
    pregnancy: 'Pregnancy',
    postmenopause: 'Postmenopause'
  };

  smokeRadioMap = {
    no: 'No, I never have',
    past: 'No, but I have in the past',
    yes: 'Yes'
  };

  alcoholRadioMap = {
    no: 'No, I never have',
    past: 'No, but I have in the past',
    yes: 'Yes'
  };

  contactLensRadioMap = {
    no: 'No, I never have',
    past: 'No, but I have in the past',
    yes: 'Yes'
  };

  stressfulDaysRadioMap = {
    least: 'Least stressful',
    moderate: 'Moderately stressful',
    extreme: 'Extremely stressful'
  };

  healthQualityRadioMap = {
    poor: 'Poor',
    fair: 'Fair',
    good: 'Good',
    excellent: 'Excellent'
  };

  hasConditionsRadioMap = {
    no: 'No',
    yes: 'Yes'
  };

  diagnosedDryEyeRadioMap = {
    uncertain: 'Uncertain',
    no: 'No',
    yes: 'Yes'
  };

  dryEyeTreatmentsRadioMap = {
    no: 'No',
    yes: 'Yes'
  };

  eyeSurgeryPerformedRadioMap = {
    no: 'No',
    yes: 'Yes'
  };

  medicationsTakenRadioMap = {
    never: 'No, I never have',
    past: 'No, but I have in the past',
    yes: 'Yes'
  };

  defaultRadioMap = {
    no: 'No',
    yes: 'Yes'
  };

  supplementsTakenRadioMap = {
    never: 'No, I never have',
    past: 'No, but I have in the past',
    yes: 'Yes'
  };

  dietQualityRadioMap = {
    poor: 'Poor',
    fair: 'Fair',
    good: 'Good',
    excellent: 'Excellent'
  };

  foodPortionRadioMap = {
    small: 'Small',
    medium: 'Medium',
    large: 'Large'
  };

  skinProductEyeIrritationRadioMap = {
    yes: 'Yes',
    no: 'No',
    maybe: 'Unsure'
  };

  eyeTearingQualityRadioMap = {
    outdoorOnly: 'Watery eyes only when outside in windy climate',
    indoorAndOutdoor: 'Watery eyes indoors and outdoors'
  };

  styeOrChalazionRadioMap = {
    once: 'Only once',
    multiple: 'Multiple times',
    never: 'Never'
  };

  sickBuildingSyndromeRadioMap = {
    no: 'No',
    yes: 'Yes',
    // tslint:disable-next-line:quotemark
    idk: 'Unsure'
  };
  bothersomeEyeRadioMap = {
    oneEyeOnly: 'One eye only',
    moreRight: 'Right more than left',
    moreLeft: 'Left more than right',
    both: 'Both equally bothersome'
  };

  sleepingPositionRadioMap = {
    side: 'I sleep on my side',
    back: 'I sleep on my back',
    stomach: 'I sleep on my stomach',
    sideAndBack: 'I sleep on both my side and back',
    sideAndStomach: 'I sleep on both my side and stomach',
    stomachAndBack: 'I sleep on both my stomach and back'
  };

  // sleepingPositionSchema = {
  //   type: 'object',
  //   properties: {
  //     sleepingPosition: {
  //       type: 'string',
  //       enum: ['side', 'back', 'stomach'],
  //       title: 'Sleeping Position',
  //       enumNames: ['I sleep on my side', 'I sleep on my back', 'I sleep on my stomach']
  //     }
  //   }  FIX LATER !!!!!!!!!!!!!!
  // };

  eyeRubbingFrequencyRadioMap = {
    occasional: 'Occasional (once a week)',
    moderate: 'Moderate (once a day)',
    extreme: 'Extreme (several times a day)'
  };

  symptomSeverityOccurrenceRadioMap = {
    dayStart: 'First thing in the morning',
    dayEnd: 'In the evening/night',
    bothStartEnd: 'Both morning and evening/night',
    noPattern: 'No particular pattern felt'
  };

  derfsFormGroupSchema = {
    aboutYou: {
      type: 'QuestionGroup',
      controls: {
        historyOfPresentingIllness: {
          type: 'FormControl',
          cderfs: true,
          mapsTo: ['historyOfPresentingIllness'],
          mapConditions: ['']
        },
        ethnicity: {
          type: 'FormGroup',
          toggleConditions: ['others'],
          toggleControlList: [['ethnicityOtherValue']],
          nonModifiableConfig: {
            nonModifiable: true,
            nonModifiableConditions: ['']
          },
          patientFileMapConfig: [
            {
              mapsTo: 'ethnicity',
              mapCondition: ''
            }
          ],
          controls: {
            ethnicityOtherValue: {
              type: 'FormControl',
              patientFileMapConfig: [
                {
                  mapsTo: 'ethnicityOtherValue',
                  mapCondition: ''
                }
              ],
              nonModifiableConfig: {
                nonModifiable: true,
                nonModifiableConditions: ['']
              }
            }
          }
        },
        gender: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            genderList: {
              type: 'SubFormGroup',
              required: true,
              controls: this.genderList,
              nonModifiableConfig: {
                nonModifiable: true,
                nonModifiableConditions: ['']
              }
            },
            menstruation: {
              type: 'FormControl',
              mapsTo: ['pregnant', 'menopausal', 'postmenopausal'],
              mapConditions: ['pregnancy', 'menopause', 'postmenopause'],
              mapValues: [true, true, true]
              // nonModifiableConfig: {
              //   nonModifiable: true,
              //   nonModifiableConditions: ['postmenopause']
              // }
            },
            childBirth: {
              type: 'FormControl',
              nonModifiableConfig: {
                nonModifiable: true,
                nonModifiableConditions: ['yes']
              }
            }
          }
        },
        smoke: {
          type: 'FormGroup',
          toggleControlList: [
            ['cigarettesPerWeek', 'vapesPerWeek'],
            ['cigarettesPerWeek', 'vapesPerWeek']
          ],
          toggleConditions: ['past', 'yes'],
          controls: {
            cigarettesPerWeek: {
              type: 'FormControl',
              default: -5,
              min: -5,
              mapsTo: ['smoking'],
              mapConditions: [1],
              mapValues: [true]
            },
            vapesPerWeek: {
              type: 'FormControl',
              default: -100,
              min: -5,
              mapsTo: ['vaping'],
              mapConditions: [1],
              mapValues: [true]
            }
          }
        },
        contactLens: {
          type: 'FormGroup',
          toggleControlList: [
            ['contactLensdaysPerWeek', 'contactType'],
            ['contactLensdaysPerWeek', 'contactType']
          ],
          toggleConditions: ['past', 'yes'],
          mapsTo: ['contactLens'],
          mapConditions: ['yes'],
          mapValues: [true],
          controls: {
            contactLensdaysPerWeek: {
              type: 'FormControl',
              default: 0,
              min: 1,
              mapsTo: ['otherConditionsFreeText'],
              mapConditions: [0],
              transformValue: derfsValue =>
                derfsValue + (derfsValue > 1 ? ' days per week / ' : ' day per week / ')
            },
            contactType: {
              type: 'FormControl',
              toggleControlList: [['contactLensDailyDesposable']],
              toggleConditions: ['soft'],
              mapsTo: ['otherConditionsFreeText'],
              mapConditions: [''],
              transformValue: (derfsValue, currentValue?) => currentValue + derfsValue + ' lens'
            },
            contactLensDailyDesposable: {
              type: 'FormControl',
              mapsTo: ['otherConditionsFreeText'],
              mapConditions: [''],
              transformValue: (derfsValue, currentValue?) =>
                currentValue +
                ' / ' +
                (derfsValue === 'no' ? ' not daily disposable' : 'daily disposable')
            }
          }
        },
        faceCareProducts: {
          type: 'FormGroup',
          cderfs: true,
          toggleControlList: [['faceCareProductsTextField']],
          toggleConditions: ['yes'],
          controls: {
            faceCareProductsTextField: {
              type: 'FormControl',
              mapsTo: ['faceCareAndMakeUpProductsFreeText'],
              mapConditions: ['']
            }
          }
        },
        skinProductEyeIrritation: {
          type: 'FormControl',
          // cderfs: true,
          mapsTo: ['skinProductEyeIrritation'],
          mapConditions: ['yes']
        },
        eyelashModification: {
          type: 'FormGroup',
          cderfs: true,
          toggleControlList: [['eyelashModificationBrands']],
          toggleConditions: ['yes'],
          nonModifiableConfig: {
            nonModifiable: true,
            nonModifiableConditions: ['yes']
          },
          controls: {
            eyelashModificationBrands: {
              type: 'FormControl',
              nonModifiableConfig: {
                nonModifiable: true,
                nonModifiableConditions: ['']
              }
            }
          }
        },
        botoxInjectionsToFace: {
          type: 'FormControl',
          // cderfs: true,
          mapsTo: ['botoxInjections'],
          mapConditions: ['yes']
        },
        difficultySwallowingFood: {
          type: 'FormControl',
          // cderfs: true,
          mapsTo: ['difficultySwallowingFood'],
          mapConditions: ['yes']
        },
        lastEyeExam: {
          type: 'FormControl',
          // cderfs: true,
          mapsTo: ['lastEyeExam'],
          mapConditions: ['']
        }
      }
    },
    foodAndDrinks: {
      type: 'QuestionGroup',
      controls: {
        alcohol: {
          type: 'FormGroup',
          toggleControlList: [['alcoholUnitsPerWeek'], ['alcoholUnitsPerWeek']],
          toggleConditions: ['past', 'yes'],
          mapsTo: ['alcohol'],
          mapConditions: ['yes'],
          mapValues: [true],
          controls: {
            alcoholUnitsPerWeek: { type: 'FormControl', default: 0, min: 2 }
          }
        },
        beverages: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            caffeine: {
              type: 'FormControl',
              default: -1,
              min: 0,
              mapsTo: ['caffeinatedDrinksPerDayFreeText'],
              mapConditions: [0],
              transformValue: derfsVal => derfsVal * 2
            },
            water: {
              type: 'FormControl',
              default: -2,
              min: 0,
              mapsTo: ['waterPerDayFreeText'],
              mapConditions: [0]
            }
          }
        },
        supplementsTaken: {
          type: 'FormGroup',
          toggleControlList: [
            ['supplementsDescription'],
            ['supplementsDescription', 'supplementsFrequency']
          ],
          toggleConditions: ['past', 'yes'],
          controls: {
            supplementsDescription: {
              type: 'FormControl',
              mapsTo: ['otherMedications'],
              mapConditions: ['']
            },
            supplementsFrequency: {
              type: 'FormControl',
              default: 0,
              min: 1,
              mapsTo: ['otherMedications'],
              mapConditions: [1],
              transformValue: (derfsVal, currentVal) => {
                switch (derfsVal) {
                  case 1:
                    return currentVal + '\nFrequency: Daily';
                  case 2:
                    return currentVal + '\nFrequency: Less than daily';
                  case 3:
                    return currentVal + '\nFrequency: Weekly';
                  case 4:
                    return currentVal + '\nFrequency: Monthly';
                }
              }
            }
          }
        },
        diet: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            dietList: { type: 'SubFormGroup', required: true, controls: this.dietList }
          }
        },
        foodIntake: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            foodIntakeList: {
              type: 'SubFormGroup',
              required: true,
              maxTotalValue: 100,
              controls: this.foodIntakeList
            }
          }
        }
      }
    },
    environment: {
      type: 'QuestionGroup',
      controls: {
        urban: {
          type: 'FormControl',
          mapsTo: ['urbanAreaTimePerWeekFreeText'],
          mapConditions: ['']
        },
        education: {
          type: 'FormControl',
          nonModifiableConfig: {
            nonModifiable: true,
            nonModifiableConditions: ['university']
          }
        },
        deviceUse: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            deviceList: {
              type: 'SubFormGroup',
              maxTotalValue: 24,
              controls: this.deviceList
            }
          }
        },
        activities: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            activityList: {
              type: 'SubFormGroup',
              maxTotalValue: 24,
              controls: this.activityList
            }
          }
        },
        sleep: {
          type: 'FormControl',
          default: -1,
          min: 0,
          mapsTo: ['sleepPerNight'],
          mapConditions: [0]
        },
        sleepingPosition: {
          type: 'FormControl',
          cderfs: true,
          mapsTo: ['sleepingPosition'],
          mapConditions: ['']
        },
        outdoors: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            outdoorHoursPerWorkDay: {
              type: 'FormControl',
              default: -1,
              min: 0,
              mapsTo: ['timeSpentOutdoorsFreeText'],
              mapConditions: [0],
              transformValue: (derfsVal, currentVal) => derfsVal + ' hours per work day\n'
            },
            outdoorHoursPerLeisureDay: {
              type: 'FormControl',
              default: -1,
              min: 0,
              mapsTo: ['timeSpentOutdoorsFreeText'],
              mapConditions: [0],
              transformValue: (derfsVal, currentVal) =>
                (currentVal || '') + derfsVal + ' hours per leisure day'
            }
          }
        },
        exercise: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            walked: {
              type: 'FormControl',
              default: -2,
              min: 0,
              mapsTo: ['exercise'],
              mapConditions: [1],
              transformValue: (derfsValue: number, currentValue) => {
                if (currentValue) return currentValue + derfsValue;
                return derfsValue;
              }
            },
            vigorousExercise: {
              type: 'FormControl',
              default: -2,
              min: 0,
              mapsTo: ['exercise'],
              mapConditions: [1],
              transformValue: (derfsValue: number, currentValue) => {
                if (currentValue) return currentValue + derfsValue;
                return derfsValue;
              }
            },
            moderateExercise: {
              type: 'FormControl',
              default: -2,
              min: 0,
              mapsTo: ['exercise'],
              mapConditions: [1],
              transformValue: (derfsValue: number, currentValue) => {
                if (currentValue) return currentValue + derfsValue;
                return derfsValue;
              }
            }
          }
        },
        airConditioned: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            airConHoursPerDay: {
              type: 'FormControl',
              default: -2,
              min: 0,
              mapsTo: ['timeSpentClosedEnvironmentsFreeText'],
              mapConditions: [0],
              transformValue: derfsVal =>
                'Air-conditioned closed environments:\n    ' + derfsVal + ' hours per day'
            },
            airConDaysPerWeek: {
              type: 'FormControl',
              default: -1,
              min: 0,
              mapsTo: ['timeSpentClosedEnvironmentsFreeText'],
              mapConditions: [0],
              transformValue: (derfsVal, currentVal) =>
                currentVal + '\n    ' + derfsVal + ' days per week'
            },
            airConMonthsPerYear: {
              type: 'FormControl',
              default: -1,
              min: 0,
              mapsTo: ['timeSpentClosedEnvironmentsFreeText'],
              mapConditions: [0],
              transformValue: (derfsVal, currentVal) =>
                currentVal + '\n    ' + derfsVal + ' months per year'
            }
          }
        },
        centralHeated: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            centralHeatedHoursPerDay: {
              type: 'FormControl',
              default: -2,
              min: 0,
              mapsTo: ['timeSpentClosedEnvironmentsFreeText'],
              mapConditions: [0],
              transformValue: (derfsVal, currentVal) =>
                currentVal +
                '\n\nCentral-heated closed environments:\n    ' +
                derfsVal +
                ' hours per day'
            },
            centralHeatedDaysPerWeek: {
              type: 'FormControl',
              default: -1,
              min: 0,
              mapsTo: ['timeSpentClosedEnvironmentsFreeText'],
              mapConditions: [0],
              transformValue: (derfsVal, currentVal) =>
                currentVal + '\n    ' + derfsVal + ' days per week'
            },
            centralHeatedMonthsPerYear: {
              type: 'FormControl',
              default: -1,
              min: 0,
              mapsTo: ['timeSpentClosedEnvironmentsFreeText'],
              mapConditions: [0],
              transformValue: (derfsVal, currentVal) =>
                currentVal + '\n    ' + derfsVal + ' months per year'
            }
          }
        },
        workHoursPerDay: {
          type: 'FormControl',
          cderfs: true,
          default: -2,
          min: 0,
          mapsTo: ['workHoursPerDayFreeText'],
          mapConditions: [0]
        },
        environmentalSymptoms: {
          type: 'FormGroup',
          cderfs: true,
          noParentControl: true,
          controls: {
            environmentalSymptomsList: {
              type: 'SubFormGroup',
              controls: this.environmentalSymptomsList
            }
          }
        },
        cityHumidityLevel: {
          type: 'FormControl',
          cderfs: true,
          default: -1,
          min: 0,
          mapsTo: ['cityHumidityLevel'],
          mapConditions: [0],
          transformValue: derfsValue => derfsValue * 10
        },
        cityPollutionLevel: {
          type: 'FormControl',
          cderfs: true,
          default: -1,
          min: 0,
          mapsTo: ['cityPollutionLevel'],
          mapConditions: [0],
          transformValue: derfsValue => derfsValue * 10
        }
      }
    },
    health: {
      type: 'QuestionGroup',
      controls: {
        stressfulDay: {
          type: 'FormControl',
          default: 0,
          min: 1,
          mapsTo: ['stressLevel'],
          mapConditions: [0],
          transformValue: (derfsValue: number, currentValue?) => derfsValue * 25
        },
        hasConditions: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            conditionsList: { type: 'SubFormGroup', controls: this.conditionsList }
          }
        },
        pastOcularHistory: {
          type: 'FormGroup',
          // cderfs: true,
          noParentControl: true,
          controls: {
            pastOcularHistoryList: {
              type: 'SubFormGroup',
              controls: this.pastOcularHistoryList
            }
          }
        },
        familyHistoryConditions: {
          type: 'FormGroup',
          cderfs: true,
          noParentControl: true,
          controls: {
            familyHistoryConditionsList: {
              type: 'SubFormGroup',
              controls: this.familyHistoryConditionsList,
              nonModifiableConfig: {
                nonModifiable: true,
                nonModifiableConditions: []
              }
            }
          }
        },
        eyeSurgeryPerformed: {
          type: 'FormGroup',
          toggleControlList: [Object.keys(this.surgeriesList), ['surgeriesListControl']],
          toggleConditions: ['yes', 'yes'],
          mapsTo: ['previousOcularSurgery'],
          mapConditions: ['yes'],
          mapValues: [true],
          nonModifiableConfig: {
            nonModifiable: true,
            nonModifiableConditions: ['yes']
          },
          controls: {
            surgeriesList: {
              type: 'SubFormGroup',
              required: true,
              controls: this.surgeriesList
            }
          }
        },
        medicationsTaken: {
          type: 'FormGroup',
          toggleControlList: [
            Object.keys(this.medicationsList),
            Object.keys(this.medicationsList),
            ['medicationsListControl'],
            ['medicationsListControl']
          ],
          toggleConditions: ['yes', 'past', 'yes', 'past'],
          controls: {
            medicationsList: {
              type: 'SubFormGroup',
              required: true,
              controls: this.medicationsList
            }
          }
        },
        eyeSensitivity: {
          type: 'FormGroup',
          cderfs: true,
          noParentControl: true,
          controls: {
            lightSensitivity: {
              min: 0,
              default: -1,
              mapsTo: ['sensitivityToLight'],
              mapConditions: [0],
              transformValue: derfsVal => derfsVal * 10
            },
            windSensitivity: {
              min: 0,
              default: -1,
              mapsTo: ['sensitivityToWind'],
              mapConditions: [0],
              transformValue: derfsVal => derfsVal * 10
            },
            impactOnMood: {
              min: 0,
              default: -1,
              mapsTo: ['impactOfSymptomsOnMood'],
              mapConditions: [0],
              transformValue: derfsVal => derfsVal * 10
            },
            impactOnLife: {
              min: 0,
              default: -1,
              mapsTo: ['impactOfSymptomsOnLife'],
              mapConditions: [0],
              transformValue: derfsVal => derfsVal * 10
            }
          }
        },
        styeOrChalazion: {
          type: 'FormControl',
          cderfs: true,
          nonModifiableConfig: {
            nonModifiable: true,
            nonModifiableConditions: ['once', 'multiple']
          }
        },
        eyeSymptoms: {
          type: 'FormGroup',
          // cderfs: true,
          noParentControl: true,
          controls: {
            eyeSymptomsList: { type: 'SubFormGroup', controls: this.eyeSymptomsList }
          }
        },

        pastDryEyeTreatments: {
          type: 'FormGroup',
          noParentControl: true,
          controls: {
            pastDryEyeTreatmentsList: {
              type: 'SubFormGroup',
              controls: this.pastDryEyeTreatmentsList
            }
          }
        },
        currentOcularMedications: {
          type: 'FormGroup',
          // cderfs: true,
          toggleControlList: [['currentOcularMedicationsValue']],
          toggleConditions: ['yes'],
          controls: {
            currentOcularMedicationsValue: {
              type: 'FormControl',
              mapsTo: ['currentOcularMedicationsFreeText'],
              mapConditions: ['']
            }
          }
        },
        pastOcularMedications: {
          type: 'FormGroup',
          cderfs: true,
          toggleControlList: [['pastOcularMedicationsValue']],
          toggleConditions: ['yes'],
          controls: {
            pastOcularMedicationsValue: {
              type: 'FormControl',
              mapsTo: ['pastOcularMedicationsFreeText'],
              mapConditions: ['']
            }
          }
        }
      }
    },
    health2: {
      type: 'QuestionGroup',
      controls: {
        hematopoieticStemCellTransplantation: {
          type: 'FormControl',
          mapsTo: ['hematopoieticStemCellTransplantation'],
          mapConditions: ['yes'],
          mapValues: [true],
          defaultMapValue: [false],
          nonModifiableConfig: {
            nonModifiable: true,
            nonModifiableConditions: ['yes']
          }
        },
        sickBuildingSyndrome: {
          type: 'FormControl',
          cderfs: true,
          mapsTo: ['pastSickBuildingSyndrome'],
          mapConditions: ['yes'],
          mapValues: [true],
          nonModifiableConfig: {
            nonModifiable: true,
            nonModifiableConditions: ['yes']
          }
        },
        autoImmuneConditions: {
          type: 'FormGroup',
          cderfs: true,
          toggleControlList: [['autoImmuneConditionsValue']],
          toggleConditions: ['yes'],
          controls: {
            autoImmuneConditionsValue: {
              type: 'FormControl',
              mapsTo: ['autoImmuneConditions'],
              mapConditions: ['']
            }
          }
        },
        healthQuality: {
          type: 'FormControl',
          cderfs: true,
          default: 0,
          min: 1,
          mapsTo: ['healthQuality'],
          mapConditions: [1],
          transformValue: derfsVal => derfsVal * 10
        },
        dietQuality: {
          type: 'FormControl',
          default: 0,
          min: 1,
          mapsTo: ['dietQuality'],
          mapConditions: [1],
          transformValue: derfsVal => derfsVal * 10
        },
        eyeItchiness: {
          type: 'FormControl',
          default: -1,
          min: 0,
          mapsTo: ['eyeItchiness'],
          mapConditions: [0],
          transformValue: derfsVal => derfsVal * 10
        },
        eyeTearing: {
          type: 'FormGroup',
          cderfs: true,
          toggleControlList: [['eyeTearingQuantity', 'eyeTearingQuality']],
          toggleConditions: ['yes'],
          mapsTo: ['tearingQuality'],
          mapConditions: ['no'],
          mapValues: ['noTearing'],
          controls: {
            eyeTearingQuantity: {
              type: 'FormControl',
              default: -1,
              min: 0,
              mapsTo: ['eyeTearingQuantity'],
              mapConditions: [0],
              transformValue: derfsVal => derfsVal * 10
            },
            eyeTearingQuality: {
              type: 'FormControl',
              default: -1,
              min: 0,
              mapsTo: ['tearingQuality'],
              mapConditions: ['']
            }
          }
        },
        bothersomeEye: {
          type: 'FormControl',
          cderfs: true,
          mapsTo: ['bothersomeEye'],
          mapConditions: ['']
        },
        eyeDrynessAndIrritability: {
          type: 'FormGroup',
          toggleControlList: [
            [
              'severityOfSymptoms',
              'frequencyOfSymptoms',
              'symptomSeverityOccurrence',
              'triggeringEvents',
              'durationOfSymptomsMonths',
              'durationOfSymptomsYears'
            ]
          ],
          toggleConditions: ['yes'],
          controls: {
            severityOfSymptoms: {
              type: 'FormControl',
              default: 0,
              min: 1,
              mapsTo: ['symptomSeverity'],
              mapConditions: [1],
              transformValue: (derfsVal, currentVal?) => derfsVal * 10
            },
            frequencyOfSymptoms: {
              type: 'FormControl',
              default: 0,
              min: 1,
              mapsTo: ['symptomFrequency'],
              mapConditions: [1],
              transformValue: derfsVal => derfsVal * 10
            },
            symptomSeverityOccurrence: {
              type: 'FormControl',
              mapsTo: ['symptomSeverityOccurrence'],
              mapConditions: ['']
            },
            triggeringEvents: {
              type: 'FormControl',
              mapsTo: ['triggeringEvent'],
              mapConditions: ['']
            },
            durationOfSymptomsMonths: {
              type: 'FormControl',
              mapsTo: ['symptomDuration'],
              mapConditions: [0],
              transformValue: (derfsVal, currentVal?) => ({ month: derfsVal, year: 0 })
            },
            durationOfSymptomsYears: {
              type: 'FormControl',
              mapsTo: ['symptomDuration'],
              mapConditions: [0],
              transformValue: (derfsVal, currentVal?) => ({
                month: currentVal.month,
                year: derfsVal
              })
            }
          }
        },
        eyeRubbingFrequency: {
          type: 'FormControl',
          cderfs: true,
          mapsTo: ['eyeRubbingFrequency'],
          mapConditions: ['']
        }
      }
    }
  };

  constructor() {}

  updateTotalValueControl(
    totalValueControlName: string,
    sectionFormGroup: FormGroup,
    controls: any
  ) {
    const totalValueControl = sectionFormGroup.controls[totalValueControlName] as FormControl;
    let value = 0;
    Object.keys(controls).forEach(key => {
      if (sectionFormGroup.controls[key + 'Value'].enabled) {
        value += +sectionFormGroup.controls[key + 'Value'].value;
      }
    });

    totalValueControl.setValue(value);
  }

  public flattenedDerfsSchema = this.buildflattenedDerfsSchema();
  public buildflattenedDerfsSchema() {
    let flattenedSchema = {};
    Object.values(this.derfsFormGroupSchema).forEach(sectionSchema => {
      const sectionSchemaControls = sectionSchema.controls;
      Object.keys(sectionSchema.controls).forEach(controlKey => {
        const questionSchema = sectionSchemaControls[controlKey];
        flattenedSchema = { ...flattenedSchema, ...{ [controlKey]: questionSchema } };

        if (sectionSchemaControls[controlKey].type === 'FormGroup') {
          Object.keys(sectionSchemaControls[controlKey].controls).forEach(childKey => {
            const controls = sectionSchemaControls[controlKey].controls;
            flattenedSchema = { ...flattenedSchema, ...{ [childKey]: controls[childKey] } };

            if (controls[childKey].type === 'SubFormGroup') {
              Object.keys(controls[childKey].controls).forEach(childControlKey => {
                flattenedSchema = {
                  ...flattenedSchema,
                  ...{ [childControlKey]: controls[childKey].controls[childControlKey] }
                };
              });
            }
            // else {
            //   flattenedSchema = { ...flattenedSchema, ...{ [childKey]: controls[childKey] } };
            // }
          });
        }
      });
    });
    return flattenedSchema;
  }

  public controlToQuestionnaireSectionMap = this.mapControlsToQuestionnaireSection();
  mapControlsToQuestionnaireSection() {
    let map = {};
    Object.keys(this.derfsFormGroupSchema).forEach(sectionKey => {
      const sectionSchema = this.derfsFormGroupSchema[sectionKey];
      const sectionSchemaControls = sectionSchema.controls;
      Object.keys(sectionSchema.controls).forEach(controlKey => {
        const questionSchema = sectionSchemaControls[controlKey];
        map = { ...map, ...{ [controlKey]: sectionKey } };

        if (sectionSchemaControls[controlKey].type === 'FormGroup') {
          Object.keys(sectionSchemaControls[controlKey].controls).forEach(childKey => {
            const controls = sectionSchemaControls[controlKey].controls;
            map = { ...map, ...{ [childKey]: sectionKey } };

            if (controls[childKey].type === 'SubFormGroup') {
              Object.keys(controls[childKey].controls).forEach(childControlKey => {
                map = {
                  ...map,
                  ...{ [childControlKey]: sectionKey }
                };
              });
            }
            // else {
            //   flattenedSchema = { ...flattenedSchema, ...{ [childKey]: controls[childKey] } };
            // }
          });
        }
      });
    });
    return map;
  }

  buildFormGroup(cderfs: boolean) {
    const derfsFormGroup = new FormGroup({});
    Object.keys(this.derfsFormGroupSchema).forEach(questionGroup => {
      const stepFormGroup = new FormGroup({});
      Object.keys(this.derfsFormGroupSchema[questionGroup].controls).forEach(key => {
        const questionGroupSchema = this.derfsFormGroupSchema[questionGroup].controls;
        if (!cderfs && questionGroupSchema[key].cderfs) {
          // do nothing
        } else if (questionGroupSchema[key].type === 'FormGroup') {
          Object.keys(questionGroupSchema[key].controls).forEach(childKey => {
            const controls = questionGroupSchema[key].controls;
            // For checkbox group
            if (controls[childKey].type === 'SubFormGroup') {
              if (controls[childKey].required) {
                stepFormGroup.addControl(
                  childKey + 'Control',
                  new FormControl(
                    { value: null, disabled: controls[childKey].disabled },
                    Validators.required
                  )
                );
              }
              if (controls[childKey].maxTotalValue) {
                stepFormGroup.addControl(
                  childKey + 'TotalValue',
                  new FormControl(
                    { value: 0, disabled: controls[childKey].disabled },
                    Validators.max(controls[childKey].maxTotalValue)
                  )
                );
              }

              Object.keys(controls[childKey].controls).forEach(element => {
                stepFormGroup.addControl(element, new FormControl(false)); // Set default checked value to unchecked
                const subKey = controls[childKey].controls[element];
                if (subKey.inputType) {
                  if (controls[childKey].required) {
                    if (subKey.inputType === 'slider') {
                      stepFormGroup.addControl(
                        element + 'Value',
                        new FormControl({ value: subKey.default, disabled: true }, [
                          Validators.required,
                          Validators.min(subKey.min)
                        ])
                      );
                    } else {
                      stepFormGroup.addControl(
                        element + 'Value',
                        new FormControl({ value: '', disabled: true }, Validators.required)
                      );
                    }
                  } else {
                    if (subKey.toggledControlRequired) {
                      stepFormGroup.addControl(
                        element + 'Value',
                        new FormControl({ value: '', disabled: true }, [Validators.required])
                      );
                    } else {
                      stepFormGroup.addControl(
                        element + 'Value',
                        new FormControl({ value: '', disabled: true })
                      );
                    }
                  }
                  if (controls[childKey].maxTotalValue) {
                    stepFormGroup.controls[element + 'Value'].valueChanges.subscribe(() =>
                      this.updateTotalValueControl(
                        childKey + 'TotalValue',
                        stepFormGroup,
                        controls[childKey].controls
                      )
                    );
                  }
                }
              });
            } else {
              if (
                controls[childKey].default !== undefined &&
                controls[childKey].min !== undefined
              ) {
                stepFormGroup.addControl(
                  childKey,
                  new FormControl(
                    { value: controls[childKey].default, disabled: controls[childKey].disabled },
                    [Validators.required, Validators.min(controls[childKey].min)]
                  )
                );
              } else {
                stepFormGroup.addControl(
                  childKey,
                  new FormControl({ value: '', disabled: true }, Validators.required)
                );
              }
            }
          });
          if (!questionGroupSchema[key].noParentControl) {
            if (
              questionGroupSchema[key].default !== undefined &&
              questionGroupSchema[key].min !== undefined
            ) {
              stepFormGroup.addControl(
                key,
                new FormControl(questionGroupSchema[key].default, [
                  Validators.required,
                  Validators.min(questionGroupSchema[key].min)
                ])
              );
            } else {
              stepFormGroup.addControl(key, new FormControl('', Validators.required));
            }
          }
        } else {
          if (
            questionGroupSchema[key].default !== undefined &&
            questionGroupSchema[key].min !== undefined
          ) {
            stepFormGroup.addControl(
              key,
              new FormControl(questionGroupSchema[key].default, [
                Validators.required,
                Validators.min(questionGroupSchema[key].min)
              ])
            );
          } else {
            stepFormGroup.addControl(key, new FormControl('', Validators.required));
          }
        }
      });
      derfsFormGroup.addControl(questionGroup, stepFormGroup);
    });
    return derfsFormGroup;
  }

  checkboxToggleKeys: string[] = ['eyeSurgeryPerformed'];

  atLeastOneCheckboxSelected(controlKeys: any[], nonModifiableData) {
    for (const key of controlKeys) {
      if (nonModifiableData[key]) {
        return true;
      }
    }
    return false;
  }

  processNonModifiableData(nonModifiableData: Object) {
    if (nonModifiableData) {
      for (const key of this.checkboxToggleKeys) {
        Object.values(this.flattenedDerfsSchema[key].controls).forEach(control => {
          if (control['type'] === 'SubFormGroup') {
            if (
              this.atLeastOneCheckboxSelected(Object.keys(control['controls']), nonModifiableData)
            ) {
              nonModifiableData = { ...nonModifiableData, ...{ [key]: 'yes' } };
            }
          }
        });
      }
    }
    return nonModifiableData;
  }

  public getDerfDataToCopy(derfsToCopyFrom: QuestionnaireRequest) {
    let answers;
    if (derfsToCopyFrom) answers = JSON.parse(derfsToCopyFrom['response']['answers']);

    const normalizedDerfsMedicalHistoryData = {};

    Object.keys(this.derfsFormGroupSchema).forEach(questionGroup => {
      if (answers[questionGroup]) {
        Object.keys(this.derfsFormGroupSchema[questionGroup].controls).forEach(key => {
          const questionGroupSchema = this.derfsFormGroupSchema[questionGroup].controls;
          if (questionGroupSchema[key].type === 'FormGroup') {
            Object.keys(questionGroupSchema[key].controls).forEach(childKey => {
              const controls = questionGroupSchema[key].controls;
              this.checkConditionAndAssignNormalizedDerfsData(
                controls[childKey],
                answers[questionGroup][childKey],
                normalizedDerfsMedicalHistoryData
              );
              // For checkboxes
              if (controls[childKey].type === 'SubFormGroup') {
                Object.keys(controls[childKey].controls).forEach(element => {
                  const childControls = controls[childKey].controls;
                  this.checkConditionAndAssignNormalizedDerfsData(
                    childControls[element],
                    answers[questionGroup][element],
                    normalizedDerfsMedicalHistoryData
                  );
                  this.checkConditionAndAssignNormalizedDerfsData(
                    childControls[element],
                    answers[questionGroup][element + 'Value'],
                    normalizedDerfsMedicalHistoryData
                  );
                });
              }
            });
            if (!questionGroupSchema[key].noParentControl) {
              this.checkConditionAndAssignNormalizedDerfsData(
                questionGroupSchema[key],
                answers[questionGroup][key],
                normalizedDerfsMedicalHistoryData
              );
            }
          } else {
            this.checkConditionAndAssignNormalizedDerfsData(
              questionGroupSchema[key],
              answers[questionGroup][key],
              normalizedDerfsMedicalHistoryData
            );
          }
        });
      }
    });

    return normalizedDerfsMedicalHistoryData;
  }

  public checkConditionAndAssignNormalizedDerfsData(
    schemaData: any,
    responseDataValue: any,
    medicalHistoryObject: any
  ) {
    if (schemaData.mapConditions === undefined) {
      return;
    }

    const mapConditionsLength = schemaData.mapConditions.length;
    for (let i = 0; i < mapConditionsLength; i++) {
      if (this.mapConditionSatisfied(schemaData.mapConditions[i], responseDataValue)) {
        this.assignNormalizedDerfsValue(schemaData, i, responseDataValue, medicalHistoryObject);
      }
    }
  }

  mapConditionSatisfied(mapCondition: any, val: any) {
    return (
      val !== undefined &&
      (val === mapCondition ||
        (typeof val === 'number' && val >= mapCondition) ||
        (mapCondition === '' && typeof val === 'string' && val.length >= 1))
    );
  }

  assignNormalizedDerfsValue(
    schemaData: any,
    mapConditionIndex: number,
    responseDataValue: any,
    medicalHistoryObject: any
  ) {
    medicalHistoryObject[schemaData.mapsTo[mapConditionIndex]] = this.getDerfsNormalizedData(
      schemaData,
      mapConditionIndex,
      responseDataValue,
      medicalHistoryObject[schemaData.mapsTo[mapConditionIndex]]
    );
  }

  private getDerfsNormalizedData(
    schemaData: any,
    mapConditionIndex: number,
    responseDataValue: any,
    currentVal
  ) {
    return (
      (schemaData.mapValues && schemaData.mapValues[mapConditionIndex]) ||
      (schemaData.transformValue && schemaData.transformValue(responseDataValue, currentVal)) ||
      responseDataValue
    );
  }

  public sampleCderfsFormGroupData = {
    aboutYou: {
      historyOfPresentingIllness: 'c',
      ethnicity: 'white',
      genderListControl: true,
      male: true,
      female: true,
      transgender: false,
      nonBinary: false,
      intersex: false,
      genderOther: false,
      preferNotToSay: false,
      menstruation: 'menopause',
      childBirth: 'yes',
      cigarettesPerWeek: 5,
      vapesPerWeek: 35,
      smoke: 'yes',
      contactLensdaysPerWeek: 2,
      contactType: 'soft',
      contactLensDailyDesposable: 'yes',
      contactLens: 'yes',
      faceCareProductsTextField: 'erber',
      faceCareProducts: 'yes',
      skinProductEyeIrritation: 'yes',
      eyelashModificationBrands: 'sfwe',
      eyelashModification: 'yes',
      botoxInjectionsToFace: 'yes',
      difficultySwallowingFood: 'yes',
      lastEyeExam: '22'
    },
    foodAndDrinks: {
      alcoholUnitsPerWeek: 8,
      alcohol: 'yes',
      caffeine: 0,
      water: 2,
      supplementsDescription: '345t',
      supplementsFrequency: 2,
      supplementsTaken: 'yes',
      dietListControl: true,
      vegan: true,
      completeVegetarian: false,
      partialVegetarian: false,
      omnivorous: false,
      oilyFish: false,
      loseWeight: false,
      foodIntakeListControl: true,
      foodIntakeListTotalValue: 1,
      animalsAndPlants: true,
      animalsAndPlantsValue: '1',
      naturalFoods: false,
      readyToEat: false,
      ultraProcessed: false
    },
    environment: {
      urban: 5,
      education: 'university',
      deviceListTotalValue: 1,
      smartphone: true,
      smartphoneValue: 1,
      tablet: false,
      laptop: false,
      desktop: false,
      activityListTotalValue: 5,
      work: false,
      socialMedia: true,
      socialMediaValue: 5,
      email: false,
      gaming: false,
      sleep: 2,
      outdoorHoursPerWorkDay: 6,
      outdoorHoursPerLeisureDay: 8,
      walked: 4,
      vigorousExercise: 8,
      moderateExercise: 12,
      airConHoursPerDay: 10,
      airConDaysPerWeek: 3,
      airConMonthsPerYear: 8,
      centralHeatedHoursPerDay: 6,
      centralHeatedDaysPerWeek: 6,
      centralHeatedMonthsPerYear: 3,
      workHoursPerDay: 4,
      throatIrritation: false,
      breathingDifficulty: false,
      chestTightness: false,
      runnyNose: true,
      allergySymptoms: true,
      noseBurningSensation: false,
      skinDrynessAndItchiness: false,
      headaches: false,
      dizziness: false,
      difficultyConcentrating: false,
      forgetfulness: false,
      fatigue: false,
      irritability: false,
      nausea: false,
      bodyAches: false,
      fever: false,
      chills: false,
      cityHumidityLevel: 2,
      cityPollutionLevel: 7
    },
    health: {
      stressfulDay: 1,
      anxiety: false,
      migraine: false,
      asthma: false,
      seasonalAllergies: false,
      drugAllergies: true,
      drugAllergiesValue: 'srgrewg',
      hypertension: false,
      glaucoma: false,
      diabetes: false,
      hypercholesterolemia: false,
      eczema: false,
      acne: false,
      rosacea: false,
      psoriasis: true,
      lupus: false,
      arthritis: false,
      osteoporosis: false,
      sclerosis: false,
      thyroid: false,
      chronic: false,
      hepatitis: true,
      hiv: false,
      htlv: false,
      stroke: true,
      cancer: false,
      vitaminDeficiency: false,
      sexSteroidDeficiency: false,
      gout: false,
      vitiligo: false,
      liverDisease: false,
      renalFailure: false,
      sjorgenSyndrome: true,
      radiotherapy: false,
      boneMarrow: false,
      irritableBowelSyndrome: false,
      polycysticOvary: false,
      fertility: false,
      pelvicPain: false,
      psychiatricConditions: false,
      demodexSkinInfection: false,
      androgenDeficiency: false,
      sarcoidosis: false,
      accutaneTreatment: false,
      dandruff: false,
      scleroderma: false,
      seborrheicDermatitis: false,
      conditionOther: false,
      wearsGlasses: true,
      pastContactLensDiscomfort: false,
      lasikOrPRK: false,
      orthokeratology: false,
      myopia: false,
      hyperopia: false,
      presbyopia: true,
      amblyopia: false,
      strabismus: false,
      eyeTrauma: true,
      pterygium: false,
      otherPastOcularHistory: false,
      diabetesHistory: false,
      highBloodPressureHistory: false,
      highCholestrolHistory: false,
      cancerHistory: false,
      autoImmuneDiseaseHistory: false,
      rosaceaHistory: true,
      glaucomaHistory: false,
      retinalDetachmentHistory: true,
      cataractsHistory: false,
      surgeriesListControl: true,
      glaucomaSurgery: false,
      cataractSurgery: true,
      strabismusSurgery: false,
      ptosisSurgery: false,
      retinalSurgery: true,
      refractiveSurgery: false,
      otherSurgery: true,
      otherSurgeryValue: 'sdfsdf',
      eyeSurgeryPerformed: 'yes',
      glaucomaDrops: false,
      antihistamineEyeDrops: false,
      antihistamineDrugs: false,
      tranquilizers: true,
      tranquilizersValue: 'dasa',
      sleepingTablets: true,
      sleepingTabletsValue: 'hnfhn',
      antidepressents: false,
      oralSteroids: false,
      pillsForSkin: false,
      diuretics: false,
      bloodPressurePills: false,
      hormoneTherapy: false,
      oralContraceptives: false,
      medicationOther: false,
      medicationsTaken: 'past',
      lightSensitivity: 1,
      windSensitivity: 3,
      impactOnMood: 2,
      impactOnLife: 6,
      styeOrChalazion: 'multiple',
      eyeDryness: true,
      eyeRedness: false,
      eyeFatigue: false,
      itching: false,
      eyeMucous: false,
      fluctuatingVision: false,
      currentContactLensDiscomfort: false,
      crustyOrDischarge: false,
      enlargedGlands: false,
      swollenEyelids: false,
      rednessGreaterInEye: false,
      artificialTears: false,
      warmCompresses: false,
      lidScrubs: false,
      pharmaceuticals: true,
      pharmaceuticalsValue: 'asdasdasqweqw',
      inOfficeTreatments: false,
      otherPastDryEyeTreatments: true,
      otherPastDryEyeTreatmentsValue: 'asdasd',
      currentOcularMedicationsValue: 'terter',
      currentOcularMedications: 'yes',
      pastOcularMedicationsValue: 'kuigbbuviyv',
      pastOcularMedications: 'yes'
    },
    health2: {
      hematopoieticStemCellTransplantation: 'yes',
      sickBuildingSyndrome: 'yes',
      autoImmuneConditionsValue: 'fgaerh',
      autoImmuneConditions: 'yes',
      healthQuality: 2,
      dietQuality: 1,
      eyeItchiness: 6,
      eyeTearingQuantity: 0,
      eyeTearingQuality: 'outdoorOnly',
      eyeTearing: 'yes',
      bothersomeEye: 'oneEyeOnly',
      severityOfSymptoms: 2,
      frequencyOfSymptoms: 4,
      symptomSeverityOccurrence: 'dayStart',
      triggeringEvents: 'sd',
      durationOfSymptomsMonths: 2,
      durationOfSymptomsYears: 4,
      eyeDrynessAndIrritability: 'yes'
    }
  };
}
