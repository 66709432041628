import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Logger } from 'aws-amplify';
import cleanDeep from 'clean-deep';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { NameFormat, TemperatureUnit } from 'src/API';
import { noSort } from 'src/app/core/api/model-utils';
import {
  ContentType,
  ImageToDataUriPipe
} from 'src/app/shared/shared-pipes/image-to-data-uri.pipe';
import { AssessmentBody } from '../../econsult/assessment-body/assessment-body.model';
import { S3AttachmentService, S3Object } from '../attachments/s3-attachment.service';
import { leftEyeKey, rightEyeKey, symptomMethodKey } from '../form.model';
import { DrugFormat } from '../medication-lookup/drug.pipe';
import { SvgPipe } from '../shared-pipes/svg.pipe';
import { SchemaService } from '../symptoms/services/schema/schema.service';
import { SymptomModes } from '../symptoms/symptom.model';
import { ClinicSetupService } from './../../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { LayoutsService } from './../../logged-in-navbar/clinic-setup-modal/layouts/layouts.service';
import { SymptomTypes } from './../symptoms/symptom.model';
import { defaultLogoUri } from './default-logo-uri.model';
import { UrlToDataUriMap } from './pdf-export-treatment/pdf-export-treatment.component';

const componentStyle = require('./dry-eye-pdf-export.component.css');
const dryEyeCategoryComponentStyle = require('./pdf-export-dry-eye-category/pdf-export-dry-eye-category.component.css');
const googleChartsComponentStyle = require('./pdf-export-donut-chart/pdf-export-donut-chart.component.css');
const pdfSummaryComponentStyle = require('./pdf-summary-export/pdf-summary-export.component.css');

export type PDFType = 'simple' | 'detailed' | 'summary';

@Component({
  selector: 'csi-dry-eye-pdf-export',
  templateUrl: './dry-eye-pdf-export.component.html',
  styleUrls: ['./dry-eye-pdf-export.component.css'],
  providers: [SvgPipe, ImageToDataUriPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DryEyePdfExportComponent implements OnInit, OnDestroy {
  public TemperatureUnit = TemperatureUnit;
  private _data: AssessmentBody;
  private _clinicLogo: S3Object;
  public defaultLogo = defaultLogoUri;
  public medicalHistoryShowFields = [];

  clinicLogoDataUri: SafeUrl;
  eyeDropImageDataUris: UrlToDataUriMap = {};

  public readonly SymptomModes = SymptomModes;

  public readonly NameFormat = NameFormat;
  public readonly DrugFormat = DrugFormat;
  public readonly SymptomTypes = SymptomTypes;
  public localMedicalHistoryFields = [];
  public localMedicationFields = [];
  public interpretation: string;
  public riskFactors: string;
  public riskFactorTitle: string;
  public htmlClassExtractor: { [key: string]: string } = {
    interpretation: 'interpretation-data-container',
    riskFactor: 'risk-factors-data-container'
  };

  public isLoaded = false;
  public loaded = false;

  public elementColumnConfigMap = {
    riskFactor: { columns: 1 }
  };

  private logger: Logger = new Logger('DryEyePdfExportComponent');
  public medHistForm: any;

  @Input('data')
  set data(value: AssessmentBody) {
    this.logger.debug('setting data');
    // remove empty objects so we can use ngIf to test for values
    this._data = cleanDeep(value) as AssessmentBody;
    this.medHistForm = this._data.medicalHistoryForm;
  }

  get data(): AssessmentBody {
    return this._data;
  }

  @Input() translateObject: any;

  @Input('clinicLogo')
  set clinicLogo(value: S3Object) {
    if (this.clinicLogo !== value) {
      this._clinicLogo = value;
      this.renderClinicLogo().subscribe();
    }
  }

  @Input() pdfType: PDFType;
  @Input() hideDataForFaxing = false;

  @Input() lang: string;

  constructor(
    private hostElementRef: ElementRef,
    public schemaService: SchemaService,
    public layoutsService: LayoutsService,
    private imageToDataUriPipe: ImageToDataUriPipe,
    private s3AttachmentService: S3AttachmentService,
    private httpClient: HttpClient,
    private changeDetector: ChangeDetectorRef,
    public clinicSetupService: ClinicSetupService,
    public translocoService: TranslocoService
  ) {}
  ngOnDestroy(): void {}

  ngOnInit() {
    const layout = this.layoutsService.allLayoutsById[this.data.dryEyeForm.assessmentMethod];

    this.localMedicationFields = [
      'patientEnteredMedicationsFreeText',
      'pastOcularMedicationsFreeText',
      'medications'
    ];

    this.localMedicalHistoryFields = ((layout && layout.medicalHistoryLayout.show) || []).filter(
      field => !this.localMedicationFields.includes(field)
    );
  }

  ngAfterViewInit() {
    if (this.pdfType === 'summary') {
      if (this._data.assessmentForm !== undefined) {
        this.extractHtmlDom(this._data.assessmentForm.impressions, true);
      }
    }
  }

  parseValue(val: any) {
    if (typeof val === 'object') {
      return Object.entries(val)
        .map(([k, v]) => `${k[0].toUpperCase() + k.substring(1)}: ${v}`)
        .join(', ');
    }
    return val;
  }

  get clinicLogo(): S3Object {
    return this._clinicLogo;
  }

  get html(): Observable<string> {
    const layout = this.layoutsService.allLayoutsById[this.data.dryEyeForm.assessmentMethod];

    this.medicalHistoryShowFields = (layout && layout.medicalHistoryLayout.show) || [];

    this.logger.debug('rendering pdf html');
    return this.render().pipe(
      delay(100),
      tap(() => {
        this.changeDetector.detectChanges();
      }),
      map(() => {
        this.changeDetector.detectChanges();
        this.logger.debug('calling innerHTMLWithStyle');
        const html = this.innerHTMLWithStyle;
        return html;
      }),
      catchError((err, caught) => {
        console.log(err, caught);
        return EMPTY;
      })
    );
  }

  private render(): Observable<boolean> {
    this.logger.debug('render() called');
    const renderClinicLogo$: Observable<boolean> = this.clinicLogoDataUri
      ? of(true)
      : this.renderClinicLogo();

    let allEyeDrops = null;
    if (this.data && this.data.assessmentForm) {
      allEyeDrops = [
        ...(this.data.assessmentForm.recommendedEyeDrops || []),
        ...(this.data.assessmentForm.recommendedRxEyeDrops || []),
        ...(this.data.assessmentForm.recommendedExercises || []),
        ...(this.data.assessmentForm.recommendedProcedures || [])
      ].filter(eyeDrop => !!eyeDrop);
    }

    const renderEyeDropImages$: Observable<boolean> = of(allEyeDrops).pipe(
      switchMap(eyeDrops => {
        const dataUris$: Observable<SafeUrl>[] = [];
        if (eyeDrops && eyeDrops.length > 0) {
          eyeDrops.forEach(eyeDrop => {
            if (!!eyeDrop.image) {
              dataUris$.push(
                this.getImageDataUri(eyeDrop.image).pipe(
                  tap(safeUrl => {
                    this.logger.debug(`setting safeUrl for ${eyeDrop.image}`);
                    this.eyeDropImageDataUris[eyeDrop.image] = safeUrl;
                  })
                )
              );
            }
          });
        }
        return dataUris$.length > 0
          ? forkJoin([...dataUris$]).pipe(
              map(() => {
                this.logger.debug('got all safe urls');
                return true;
              })
            )
          : of(true);
      })
    );

    this.logger.debug('returning fork join');

    return forkJoin([renderClinicLogo$, renderEyeDropImages$]).pipe(
      map(() => {
        this.logger.debug('forkJoin completed - rendered all images');
        return true;
      })
    );
  }

  private renderClinicLogo(): Observable<boolean> {
    this.logger.debug('rendering clinic logo');
    return of(this.clinicLogo).pipe(
      switchMap(logo => (logo ? this.s3AttachmentService.s3ObjectToUri(logo) : of(null))),
      map(logoDataUri => {
        this.logger.debug('setting logo data uri');
        this.clinicLogoDataUri = logoDataUri;

        return true;
      })
    );
  }

  private getImageDataUri(url: string): Observable<SafeUrl> {
    this.logger.debug(`getImageDataUri: getting ${url}`);
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      switchMap(imageBlob => {
        this.logger.debug('got image blob');
        return this.imageToDataUriPipe.transform(imageBlob, imageBlob.type as ContentType, true);
      })
    );
  }

  private get innerHTMLWithStyle(): string {
    this.logger.debug('setting inner html');
    return this.hostElement.innerHTML.replace(
      '</head>',
      `<style>${componentStyle.default} ${dryEyeCategoryComponentStyle.default} ${googleChartsComponentStyle.default} ${pdfSummaryComponentStyle.default}</style></head>`
    );
  }

  public hasValue(value: string): boolean {
    if (value === null || value === undefined) {
      return false;
    } else {
      return value.toString().length > 0;
    }
  }

  get assessmentDate(): Date {
    // TODO: Support custom assessment date
    return new Date();
  }

  public shouldShowSymptom(symptomKey: string): boolean {
    return (
      (this.isSimpleMode && this.isSimpleSymptom(symptomKey)) ||
      (this.isAdvancedMode && this.isAdvancedSymptom(symptomKey))
    );
  }

  public shouldShowSymptomMethod(symptomKey: string): { right: boolean; left: boolean } {
    return {
      right:
        this.schemaService.symptomMap[symptomKey].type === SymptomTypes.Method &&
        this.hasValue(rightEyeKey(symptomMethodKey(symptomKey))) &&
        this.hasValue(rightEyeKey(symptomKey)),

      left:
        this.schemaService.symptomMap[symptomKey].type === SymptomTypes.Method &&
        this.hasValue(leftEyeKey(symptomMethodKey(symptomKey))) &&
        this.hasValue(leftEyeKey(symptomKey))
    };
  }

  public isSimpleSymptom(symptomKey: string): boolean {
    return this.schemaService.symptomMap[symptomKey].modes.indexOf(SymptomModes.Simple) !== -1;
  }

  public isAdvancedSymptom(symptomKey: string): boolean {
    return this.schemaService.symptomMap[symptomKey].modes.indexOf(SymptomModes.Advanced) !== -1;
  }

  get isSimpleMode(): boolean {
    return this.data.dryEyeForm.assessmentMethod === SymptomModes.Simple;
  }

  get isAdvancedMode(): boolean {
    return this.data.dryEyeForm.assessmentMethod === SymptomModes.Advanced;
  }

  private get hostElement(): HTMLElement {
    return this.hostElementRef.nativeElement;
  }
  public noSort = noSort;

  public extractHtmlDom(htmlBody: string, removeFromHtml: boolean = false) {
    if (!htmlBody) {
      return;
    }

    const parser = new DOMParser();
    const dom = parser.parseFromString(htmlBody, 'text/html');

    if (dom.querySelector('.interpretation-data-container')) {
      const interpretationContainer = dom.querySelector(
        '.interpretation-data-container'
      ) as HTMLElement;

      this.interpretation = interpretationContainer.innerHTML;
      interpretationContainer.remove();
    }

    if (dom.querySelector('.risk-factors-data-container')) {
      const riskFactorContainer = dom.querySelector('.risk-factors-data-container') as HTMLElement;

      this.riskFactorTitle = (riskFactorContainer.querySelector(
        '.risk-factor-title'
      ) as HTMLElement).outerHTML;
      riskFactorContainer.querySelector('.risk-factor-title').remove();

      this.riskFactors = riskFactorContainer.innerHTML;
      this.elementColumnConfigMap.riskFactor.columns =
        this.riskFactors.match(/<\/li>/gi).length >= 10 ? 2 : 1;

      riskFactorContainer.remove();
    }

    this._data.assessmentForm.impressions = new XMLSerializer().serializeToString(dom);
  }
}
