import { Injectable } from '@angular/core';
import { DryEyeMedicalHistoryFormGroup } from 'src/app/shared/consult-forms/dry-eye-forms/dry-eye-medical-history-form/dry-eye-medical-history-form.model';
import { FieldStatus } from '../layouts.service';

@Injectable({
  providedIn: 'root'
})
export class MedicalHistoryLayoutService {
  public medicalHistoryFields = {
    lastEyeExam: 'Last Eye Exam',
    historyOfPresentingIllness: 'History of Presenting Illness and Triggering Event',
    changesToEyes: 'Changes To Eyes Since Any Previous Assessment',
    symptomDuration: 'Symptoms Duration',
    autoImmuneConditions: 'Auto-Immune Conditions',
    seasonalAllergies: 'Seasonal Allergies',
    drugAllergies: 'Drug Allergies',
    medications: 'Medications',
    otherMedications: 'Nutraceuticals',
    exercise: 'Exercise Per Week',
    otherConditions: 'Other Conditions',
    pastDryEyeTreatmentsFreeText: 'Past Dry Eye Treatments',
    medicalConditions: 'Medical Conditions',
    environmentalSymptoms: 'Frequent symptoms at work or home',
    eyeItchiness: 'Eye Itchiness',
    eyeTearingQuantity: 'Eye Tearing Quantity',
    dietQuality: 'Diet Quality',
    healthQuality: 'Health Quality',
    stressLevel: 'Stress Level',
    cityHumidityLevel: 'City Humidity Level',
    cityPollutionLevel: 'City Pollution Level',
    sensitivityToLight: 'Sensitivity to light',
    sensitivityToWind: 'Sensitivity to wind',
    impactOfSymptomsOnMood: 'Impact of symptoms on mood',
    impactOfSymptomsOnLife: 'Impact of symptoms on daily life',
    symptomFrequency: 'Frequency of Symptoms',
    symptomSeverity: 'Severity of Symptoms',
    tearingQuality: 'Eye tearing quality',
    screenTime: 'Screen time / day (smartphones, computers, etc..)',
    bothersomeEye: 'Which eye bothers you?',
    sleepingPosition: 'Sleeping position',
    eyeRubbingFrequency: 'Eye rubbing frequency',
    symptomSeverityOccurrence: 'Symptom severity occurrence',
    previousOcularSurgeryFreeText: 'Past ocular history',
    sunTanExposureFrequency: 'Frequency of sun exposure for tanning purposes.',
    lastSunExposure: 'Last sun/UV exposure',
    eyeColor: 'Eye color',
    longSunExposure: 'Skin reaction after long sun exposure',
    naturalHairColor: 'Natural hair color',
    darkerAfterSunExposure: 'Skin tan after sun exposure',
    skinColor: 'Skin color',
    faceSunSensitivity: 'Face sensitivity to sun',
    freckles: 'Quantity of freckles on face',
    tanIntensity: 'Tan intensity after sun exposure'
  };

  public medicalHistoryLayout: FieldStatus;

  constructor() {}

  setMedicalHistoryLayout(medicalHistoryLayout: FieldStatus) {
    this.medicalHistoryLayout = this.getMedicalHistoryLayout(medicalHistoryLayout);
  }

  getMedicalHistoryLayout(medicalHistoryLayout: FieldStatus): FieldStatus {
    if (!medicalHistoryLayout) {
      return { show: Object.keys(this.medicalHistoryFields), hide: [] };
    }

    // Make sure all the new fields that are added show by default
    const hide = medicalHistoryLayout.hide;
    const show = [
      ...medicalHistoryLayout.show,
      ...Object.keys(this.medicalHistoryFields).filter(
        medicalHistoryField =>
          !medicalHistoryLayout.show.includes(medicalHistoryField) &&
          !hide.includes(medicalHistoryField)
      )
    ];
    return { show, hide };
  }

  allApplicableMedicalHistoryForAssessment(
    dryEyeMedicalHistoryFormGroup: DryEyeMedicalHistoryFormGroup
  ): string[] {
    const medicalHistoryLayoutToShow = this.getMedicalHistoryLayout(this.medicalHistoryLayout).show;

    Object.entries(dryEyeMedicalHistoryFormGroup.controls).forEach(([key, control]) => {
      if (
        control.value &&
        (!(control.value instanceof Object) || Object.keys(control.value).length > 0) &&
        !medicalHistoryLayoutToShow.includes(key) &&
        key !== 'schemaVersion'
      ) {
        medicalHistoryLayoutToShow.push(key);
      }
    });

    return medicalHistoryLayoutToShow;
  }
}
