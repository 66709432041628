import { Injectable } from '@angular/core';
import {
  Compare,
  FormFieldConfig,
  FormTemplateSchema,
  TriggerGroupType
} from '../form-template-model';

@Injectable({
  providedIn: 'root'
})
export class FormTemplateGeneratorService {
  public supportingFields: { [key: string]: FormFieldConfig };
  public readOnly = false;
  public formatForPDF = false;
  public fieldsHiddenForPDFExportMap: { [key: string]: boolean };

  constructor() {
    // [dedq as FormTemplateSchema].forEach(questionnaire => {
    //   Object.values(questionnaire.supportingFields).forEach((field: FormFieldConfig) => {
    //     Object.values(field.typeConfig['valuesConfig'] || []).forEach(
    //       (valueConfig: ValueConfig, i) => {
    //         console.log(valueConfig);
    //         valueConfig.sort *= 100;
    //       }
    //     );
    //   });
    //   Object.values(questionnaire.templateGroups).forEach((group: FormTemplateGroup) => {
    //     group.sort *= 100;
    //     Object.values(group.formFields).forEach((field, j) => {
    //       field.sort *= 100;
    //       Object.values(field.typeConfig['valuesConfig'] || []).forEach(
    //         (valueConfig: ValueConfig, i) => {
    //           valueConfig.sort *= 100;
    //         }
    //       );
    //     });
    //   });
    // });
    // console.log(JSON.stringify(dedq));
    // console.log(JSON.stringify(oliverQuestionnaire));
    // console.log(JSON.stringify(patientIntakeQuestion));
  }

  updateFieldsHiddenForPDFExportMap(key: string, hide: boolean) {
    this.fieldsHiddenForPDFExportMap = {
      ...this.fieldsHiddenForPDFExportMap,
      [key]: hide
    };
  }

  sortFormFieldsBySortKey(questionaire: FormTemplateSchema) {
    if (!questionaire || !questionaire.templateGroups) return questionaire; // Check if data or templateGroups is null/undefined

    Object.values(questionaire.templateGroups).forEach(group => {
      if (!group.formFields) return; // Check if formFields is null/undefined

      // Convert formFields to an array, sort it, and then rebuild the object

      const a = Object.entries(group.formFields)
        .sort(([, a], [, b]) => a.sort - b.sort)
        .reduce((acc, [key, fieldSchema]) => {
          return {
            ...acc,
            [key]: fieldSchema
          };
        }, {});
    });

    return questionaire;
  }

  evaluateTrigger(a: any, b: any, compareOperator: Compare) {
    a = this.convertToLowerCaseIfString(a);
    b = this.convertToLowerCaseIfString(b);
    switch (compareOperator) {
      case Compare.equals:
        return a === b;
      case Compare.lessThan:
        return a < b;
      case Compare.greaterThan:
        return a > b;
      case Compare.greaterThanOrEqual:
        return a >= b;
      case Compare.lessThanOrEqual:
        return a <= b;
      default:
        console.log('unknown comparison operator');
        return false;
    }
  }

  compareByTriggerGroupType(a: boolean, b: boolean, triggerGroupType: TriggerGroupType) {
    switch (triggerGroupType) {
      case TriggerGroupType.and:
        return a && b;
      case TriggerGroupType.or:
        return a || b;
      default:
        console.log('unknown trigger group operator');
        return false;
    }
  }

  convertToLowerCaseIfString(s: any) {
    if (typeof s === 'string') {
      return s.toLowerCase();
    }
    return s;
  }
}
