import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { Doctor, NameFormat } from 'src/API';
import { StaffService } from 'src/app/core/api/staff.service';
import { AppSyncService } from 'src/app/core/appsync.service';
import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
import { deactivateStaff } from 'src/graphql/mutations';
import { ClinicService } from '../../../core/api/clinic.service';
import { ClinicSetupModalComponent } from '../clinic-setup-modal.component';
import { reactivateStaff } from './../../../../graphql/mutations';
import { ClinicSetupService } from './../clinic-setup.service';
import { AccountAccessInstructionsModalComponent } from './account-access-instructions-modal/account-access-instructions-modal.component';
import { StaffActionsModalComponent } from './staff-actions-modal/staff-actions-modal.component';

@Component({
  selector: 'csi-all-doctors-table',
  templateUrl: './all-doctors-table.component.html',
  styleUrls: ['./all-doctors-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllDoctorsTableComponent implements OnInit {
  @Input() clinicId: string;

  public clinicStaffs: Partial<Doctor>[] = [];

  public columnsToDisplay = ['name', 'email', 'userType'];
  public nameFormat: NameFormat = NameFormat.Fullname;
  public skipAutoCapitalization = true;
  public loadingDoctors = false;

  public referralOnly = !this.staffService.staff.cognitoGroups.includes('DryEyeExpertPanel');

  constructor(
    private clinicService: ClinicService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private confirmModalService: ConfirmModalService,
    public clinicSetupService: ClinicSetupService,
    public appSyncService: AppSyncService,
    public staffService: StaffService,
    public dialogRef: MatDialogRef<ClinicSetupModalComponent>
  ) {}

  ngOnInit() {
    if (this.clinicSetupService.isClinicAdmin) {
      this.columnsToDisplay.push('action');
    }

    this.loadStaffs();
  }

  loadStaffs(disableLocalLoad = false) {
    if (!disableLocalLoad) {
      this.loadingDoctors = true;
    }
    this.changeDetectorRef.markForCheck();
    this.clinicService.getClinicStaff(this.clinicId, 'network-only').subscribe(clinicStaffs => {
      this.loadingDoctors = false;
      this.clinicStaffs = this.sortDoctors(clinicStaffs);
      this.clinicSetupService.isSaving = false;
      this.changeDetectorRef.markForCheck();
    });
  }

  sortDoctors(clinicStaffs: Partial<Doctor>[]): Partial<Doctor>[] {
    return clinicStaffs
      .sort((a, b) => a.firstName.localeCompare(b.firstName))
      .sort((a, b) => Number(a.deactivated) - Number(b.deactivated));
  }

  changeStatus(staffId, status: 'activate' | 'deactivate') {
    this.clinicStaffs.find(clinicDoctor => clinicDoctor.id === staffId).deactivated =
      status === 'deactivate';
  }

  addStaff() {
    this.dialog
      .open(StaffActionsModalComponent, { data: { action: 'add' } })
      .afterClosed()
      .subscribe(addedStaff => {
        if (addedStaff) {
          this.loadStaffs();
        }
      });
  }

  updateStaff(staff: Doctor) {
    this.dialog
      .open(StaffActionsModalComponent, { data: { action: 'update', staff } })
      .afterClosed()
      .subscribe((updatedStaff: Doctor) => {
        if (updatedStaff) {
          this.loadStaffs();
        }
      });
  }

  toggleStaffStatus(staffId: string, status: 'activate' | 'deactivate') {
    const deactivate = status === 'deactivate';

    const confirmation$: Observable<boolean> = deactivate
      ? this.confirmModalService.show(
          'Deactivate User',
          'Deactivating the staff will disable their user account, they wont be able to sign in.<br> You will be able reactivate this user again.',
          'Deactivate',
          'Cancel'
        )
      : this.confirmModalService.show(
          'Activate User',
          'Activating the staff will enable their user account, they will have access to the clinic data. They will be added as a Receptionist. <br>' +
            'Do you want to proceed ? ',
          'Activate',
          'Cancel'
        );

    confirmation$
      .pipe(
        mergeMap(confirmed => {
          if (confirmed) {
            this.dialogRef.disableClose = true;
            this.clinicSetupService.isSaving = true;
            this.changeDetectorRef.markForCheck();

            return this.appSyncService.hydrated().pipe(
              switchMap(client => {
                return client.mutate({
                  mutation: gql(deactivate ? deactivateStaff : reactivateStaff),
                  variables: { staffId }
                });
              })
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe(result => {
        this.dialogRef.disableClose = false;

        if (!!result) {
          this.loadStaffs(true);
        }
      });
  }

  accountAccessInstructions() {
    this.dialog.open(AccountAccessInstructionsModalComponent);
  }
}
