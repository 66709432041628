import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { TemperatureUnit } from 'src/API';

interface WeatherFormControls {
  temp: FormControl;
  tempUnit: FormControl;
  humidity: FormControl;
}

export class WeatherFormGroup extends FormGroup {
  public controls: WeatherFormControls & { [key: string]: AbstractControl };

  constructor() {
    const regex = /^[-]?\d+(\.\d+)?$/;
    super({
      temp: new FormControl(null, [temperatureValidator(), Validators.pattern(regex)]),
      tempUnit: new FormControl(),
      humidity: new FormControl(null, [
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(regex)
      ])
    });
  }
}

export function temperatureValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const tempMap = {
      [TemperatureUnit.celsius]: { min: -100, max: 100 },
      [TemperatureUnit.fahrenheit]: { min: -150, max: 200 }
    };

    const unit =
      (control.parent && control.parent.value && control.parent.value['tempUnit']) ||
      TemperatureUnit.celsius;

    return control.value >= tempMap[unit].min && control.value <= tempMap[unit].max
      ? null
      : { rejected: 'Temperature is out of bounds' };
  };
}
