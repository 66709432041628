import { Symptom } from '../../symptom.model';

export interface SchemaJSON {
  version: string;
  medicalHistory: MedicalHistorySchema;
  speed: Symptom;
  speedii: Symptom;
  deq: Symptom;
  osdi: Symptom;
  fitzpatrick: Symptom;
  symptoms: SymptomsSchema;
}

export interface MedicalHistorySchema {
  usageReasons: KeyValueMap;
  checkboxConditions: KeyValueMap;
  medialConditions: KeyValueMap;
  otherConditionsFreeText: string;
  radioConditions: RadioConditionsSchema;
}

export interface RadioConditionsSchema {
  [key: string]: {
    name: string;
    values: KeyValueMap;
  };
}

export interface SymptomsSchema {
  [key: string]: Symptom;
}

export interface KeyValueMap {
  [key: string]: string;
}

export enum TelangiectasiaSeverity {
  NORMAL = 'normal',
  MILD = 'mild',
  MODERATE = 'moderate',
  SEVERE = 'severe'
}
export const CONTACT_LENS_FIELD = 'contactLens';
export const OSDI = 'osdi';
export const SPEED = 'speed';
export const SPEEDII = 'speedII';
export enum SymptomKey {
  TELANGIECTASIA = 'EYELID_TELANGIECTASIA',
  MGE_SECRETION = 'MGE_SECRETION',
  CORNEAL_STAINING_EXTENT = 'CORNEAL_STAINING_EXTENT',
  TEAR_MENISCUS_HEIGHT = 'TEAR_MENISCUS_HEIGHT',
  NITBUT = 'NITBUT'
}
