import { Component, ElementRef, Input, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ApolloError } from 'apollo-client';
import { ErrorHandlerService } from 'src/app/core/api/error-handler.service';
import { PatientService } from 'src/app/core/api/patient.service';
import { QuestionnaireRequestService } from 'src/app/core/api/questionnaire-request.service';
import { FormErrorScrollingService } from 'src/app/shared/form-error-scrolling/form-error-scrolling.service';
import { QuestionnaireRoutePath } from '../questionnaires-routing.module';
import { ConsentFormComponent } from '../simple-questionnaire/consent-form/consent-form.component';
import { QuestionnaireRequestResponse } from '../simple-questionnaire/submit-questionnaire-step/submit-questionnaire-step.component';
import {
  ConsentSource,
  QuestionnaireRequestResponseInput,
  UpdateQuestionnaireRequestInput
} from './../../../API';
import { LoadingSpinnerService } from './../../shared/loading-spinner/loading-spinner.service';
import { CompareValueToConditionsPipe } from './../../shared/shared-pipes/compare-value-to-conditions.pipe';
import { QuestionnaireRequestData } from './../questionnaire-request/questionnaire-request-data.service';
import { DerfsService } from './derfs.service';

@Component({
  selector: 'csi-derfs',
  templateUrl: './derfs.component.html',
  styleUrls: ['./derfs.component.scss'],
  providers: [CompareValueToConditionsPipe]
})
export class DerfsComponent implements OnInit {
  @Input() public readOnly = false;
  @Input() private responseData;
  @Input() public cderfs = false;
  @Input() hideNonModifiableData = false;

  public submitBtnDisabled = false;
  public useSampleCderfsData = false; // ONLY FOR TESTING SET TO TRUE. SHOULD BE FALSE BY DEFAULT

  public formGroup = new FormGroup({});
  public patchedNonModifiableData = {};
  public nonModifiableData;
  // public savedNonModifiableDataFormGroup = new FormGroup({});

  // Handle case where CDERFS has not been sent, but non modifiable data exists due to the patient file being modified.
  public nonModifiableGenderOptionSelected = false;
  public nonModifiableFamilyHistoryOptionSelected = false;

  submitFormGroup = new FormGroup({
    agreedToTerms: new FormControl(
      this.questionnaireRequestData && this.questionnaireRequestData.data.consentsToPrivacyForm,
      Validators.requiredTrue
    )
  });

  constructor(
    @Optional() public questionnaireRequestData: QuestionnaireRequestData,
    public questionnaireRequestService: QuestionnaireRequestService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formErrorScrollingService: FormErrorScrollingService,
    private hostElementRef: ElementRef,
    public derfsService: DerfsService,
    public loadingSpinnerService: LoadingSpinnerService,
    public patientService: PatientService,
    public compareValueToConditionsPipe: CompareValueToConditionsPipe,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    if (this.questionnaireRequestData) {
      this.nonModifiableData = JSON.parse(this.questionnaireRequestData.data.nonModifiableData);
      this.checkHideNonModifiableDataConditions();
    }

    this.formGroup = this.derfsService.buildFormGroup(this.cderfs);
    if (this.useSampleCderfsData && !this.readOnly) {
      this.formGroup.patchValue(this.derfsService.sampleCderfsFormGroupData);
      if (!this.readOnly) this.enablePatchedControls();
    } else if (this.responseData) {
      this.formGroup.patchValue(JSON.parse(this.responseData.answers));
    }
    this.patchFormGroupWithNonModifiableData();
    if (this.readOnly) {
      this.formGroup.disable();
    } else {
      this.enablePatchedControls();
    }
  }

  checkHideNonModifiableDataConditions() {
    if (!this.nonModifiableData) {
      this.hideNonModifiableData = false;
      return;
    }
    this.nonModifiableGenderOptionSelected = Object.keys(
      this.derfsService.flattenedDerfsSchema['genderList']['controls']
    ).some(key => this.nonModifiableData[key]);
    this.nonModifiableFamilyHistoryOptionSelected = Object.keys(
      this.derfsService.flattenedDerfsSchema['familyHistoryConditionsList']['controls']
    ).some(key => this.nonModifiableData[key]);
  }

  noSort() {
    return 0;
  }

  enablePatchedControls() {
    Object.keys(this.derfsService.derfsFormGroupSchema).forEach(questionGroup => {
      Object.keys(this.derfsService.derfsFormGroupSchema[questionGroup].controls).forEach(key => {
        const questionGroupSchema = this.derfsService.derfsFormGroupSchema[questionGroup].controls;
        if (!this.cderfs && questionGroupSchema[key].cderfs) {
        } else if (questionGroupSchema[key].type === 'FormGroup') {
          Object.keys(questionGroupSchema[key].controls).forEach(childKey => {
            this.toggleFormControls([questionGroup, key], childKey);
            const controls = questionGroupSchema[key].controls;
            // For checkbox group
            if (controls[childKey].type === 'SubFormGroup') {
              Object.keys(controls[childKey].controls).forEach(element => {
                this.toggleFormControls(
                  [questionGroup, key, childKey],
                  element,
                  controls[childKey].required
                );
              });
            }
          });
          if (!questionGroupSchema[key].noParentControl) {
            this.toggleFormControls([questionGroup], key);
          }
        } else {
          this.toggleFormControls([questionGroup], key);
        }
      });
    });
  }

  // enablePatchedControls() {
  //   Object.values(this.formGroup.controls).forEach(sectionFormGroup => {
  //     Object.values(sectionFormGroup['controls']).forEach((control: FormControl) => {
  //       control.disable();
  //     });

  //     Object.keys(sectionFormGroup['controls']).forEach(key => {
  //       if (
  //         sectionFormGroup['controls'][key].value !== null ||
  //         sectionFormGroup['controls'][key].value !== undefined
  //       ) {
  //         sectionFormGroup['controls'][key].enable();
  //       }
  //     });
  //   });
  //   console.log(this.formGroup.value);
  // }
  // Fill in the derfs with non-modifiable data, then disable the non-modifiable controls
  patchFormGroupWithNonModifiableData() {
    this.nonModifiableData = this.derfsService.processNonModifiableData(this.nonModifiableData);
    if (this.nonModifiableData) {
      Object.keys(this.formGroup.controls).forEach(sectionFormGroupKey => {
        const sectionFormGroup = this.formGroup.controls[sectionFormGroupKey];
        Object.keys(sectionFormGroup['controls']).forEach(key => {
          if (
            this.nonModifiableData[key] &&
            this.derfsService.flattenedDerfsSchema[key] &&
            this.derfsService.flattenedDerfsSchema[key].nonModifiableConfig &&
            this.compareValueToConditionsPipe.transform(
              this.nonModifiableData[key],
              this.derfsService.flattenedDerfsSchema[key].nonModifiableConfig
                .nonModifiableConditions
            )
          ) {
            // console.log(
            //   `Compare ${key} result: ${this.compareValueToConditionsPipe.transform(
            //     this.nonModifiableData[key],
            //     this.derfsService.flattenedDerfsSchema[key].nonModifiableConfig
            //       .nonModifiableConditions
            //   )} `
            // );
            sectionFormGroup['controls'][key].patchValue(this.nonModifiableData[key]);
            this.patchedNonModifiableData = {
              ...this.patchedNonModifiableData,
              ...{ [key]: this.nonModifiableData[key] }
            };

            // Patch values for toggleable fields
            if (this.derfsService.flattenedDerfsSchema[key].inputType) {
              const toggleableFieldKey = key + 'Value';
              sectionFormGroup['controls'][toggleableFieldKey].patchValue(
                this.nonModifiableData[toggleableFieldKey]
              );
              this.patchedNonModifiableData = {
                ...this.patchedNonModifiableData,
                ...{ [toggleableFieldKey]: this.nonModifiableData[toggleableFieldKey] }
              };
            }
          }
        });
      });
    }
    // console.log('Saved FormGroup', this.savedNonModifiableDataFormGroup.value);
  }

  openPrivacyConsentForm(event) {
    event.preventDefault();

    this.dialog.open(ConsentFormComponent, {
      data: { country: this.questionnaireRequestData.data.country }
    });
  }

  toggleCheckBoxControl(schemaPath: string[]) {
    let schema = this.derfsService.derfsFormGroupSchema;
    schemaPath.forEach(schemaKey => {
      schema = schema[schemaKey].controls;
    });

    const listControlName = schemaPath[2] + 'Control';
    const sectionFormGroup = this.formGroup.controls[schemaPath[0]] as FormGroup;

    let checked = false;
    Object.keys(schema).forEach(checkboxControl => {
      if (sectionFormGroup.controls[checkboxControl].value === true) {
        checked = true;
      }
    });

    if (checked) {
      sectionFormGroup.controls[listControlName].setValue(true);
    } else {
      sectionFormGroup.controls[listControlName].setValue(null);
    }
  }

  toggleFormControls(schemaPath: string[], key: string, checkboxRequired?: boolean) {
    if (this.readOnly) return;
    setTimeout(() => {
      let schema = this.derfsService.derfsFormGroupSchema;
      schemaPath.forEach(schemaKey => {
        schema = schema[schemaKey].controls;
      });
      const formControlSchema = schema[key];
      if (checkboxRequired) {
        this.toggleCheckBoxControl(schemaPath);
      }

      if (!formControlSchema['toggleConditions']) return;
      const toggleConditions = formControlSchema['toggleConditions'];

      const sectionFormGroup = this.formGroup.controls[schemaPath[0]] as FormGroup;

      const lockedKeys = [];
      for (let i = 0; i < toggleConditions.length; i++) {
        const enableTrigger = toggleConditions[i];
        if (
          sectionFormGroup.controls[key].value === enableTrigger &&
          formControlSchema['toggleControlList']
        ) {
          // Enable controls
          const list = formControlSchema['toggleControlList'][i];
          for (const controlKey of list) {
            sectionFormGroup.controls[controlKey].enable();
            lockedKeys.push(controlKey);
            if (formControlSchema.controls) {
              let nestedToggledControl = formControlSchema.controls[controlKey];
              if (nestedToggledControl && nestedToggledControl.toggleControlList) {
                this.toggleFormControls([...schemaPath, key], controlKey);
              }
            }
          }
        } else {
          // Disable controls
          const list = formControlSchema['toggleControlList'];
          for (const controlsToEnable of list) {
            for (const controlKey of controlsToEnable) {
              let locked = false;
              for (const lockedKey of lockedKeys) {
                if (controlKey === lockedKey) locked = true;
              }
              if (locked) return;
              sectionFormGroup.controls[controlKey].disable();
              if (formControlSchema.controls) {
                let nestedToggledControl = formControlSchema.controls[controlKey];
                if (nestedToggledControl && nestedToggledControl.toggleControlList) {
                  for (let controlToDisable of nestedToggledControl.toggleControlList) {
                    sectionFormGroup.controls[controlToDisable].disable();
                  }
                }
              }
            }
          }
        }
      }
    });
  }

  attachCderfsNonModifiableDataToFormGroup() {
    if (this.cderfs || !this.nonModifiableData) return;
    Object.keys(this.nonModifiableData).forEach(key => {
      if (!this.patchedNonModifiableData[key]) {
        const sectionFormGroup = this.formGroup.controls[
          this.derfsService.controlToQuestionnaireSectionMap[key]
        ] as FormGroup;
        sectionFormGroup.addControl(
          key,
          new FormControl({ value: this.nonModifiableData[key], disabled: false })
        );
      }
    });
  }

  next(formGroup: FormGroup) {
    if (!formGroup.valid) {
      this.formErrorScrollingService.scrollToFirstInvalidControlInView(this.hostElementRef, true, {
        isHostScrollable: true,
        isHostMatStep: true
      });

      return;
    }

    this.submit(false);
  }

  // test() {}
  submit(completed: boolean = true) {
    if (completed) this.attachCderfsNonModifiableDataToFormGroup();
    const response: QuestionnaireRequestResponse = {
      answers: this.formGroup.value,
      scores: this.cderfs ? { CDERFS: -1 } : { DERFS: -1 },
      consent: {
        agreedToTerms: true,
        consentsToResearch: true,
        consentSource: ConsentSource.Patient,
        consentsToPrivacyForm: true
      }
    };
    if (this.submitFormGroup.valid || !completed) {
      const input: UpdateQuestionnaireRequestInput = {
        id: this.questionnaireRequestData.data.id,
        response: this.stringifyResponse(response)
      };
      if (completed) {
        this.submitBtnDisabled = true;
        input['completedAt'] = new Date().toISOString();
      }
      this.questionnaireRequestService.updateQuestionnaireRequest(input).subscribe(
        () => {
          if (completed) this.redirectToSuccessPage(response);
        },
        (error: ApolloError) => {
          this.errorHandlerService.handleGraphQLError(error);
        }
      );
    }
  }

  private redirectToSuccessPage(response: QuestionnaireRequestResponse) {
    this.router.navigate([`../${QuestionnaireRoutePath.ThankYou}`], {
      relativeTo: this.activatedRoute,
      queryParams: {
        isPositive: this.questionnaireRequestService.isAtRisk(JSON.stringify(response.scores)),
        derfs: 'true',
        lang: this.translocoService.getActiveLang()
      }
    });
  }

  private stringifyResponse(
    response: QuestionnaireRequestResponse
  ): QuestionnaireRequestResponseInput {
    return {
      answers: JSON.stringify(response.answers),
      scores: this.cderfs ? JSON.stringify({ CDERFS: 'N/A' }) : JSON.stringify({ DERFS: 'N/A' }),
      consent: response.consent
    };
  }
}
