import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { NameFormat } from 'src/API';
import { SUPPORT_EMAIL_ACCESSOR } from '../../../core/domain-accessor';
import { PersonNamePipe } from '../../shared-pipes/person-name.pipe';
import { phoneNumberAndCountryCodeValidator } from '../../shared-validators/phone-number-validator';
import { StaffService } from './../../../core/api/staff.service';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { DoctorFormGroup } from './doctor-form.model';
import { mfaValidator } from './select-mfa-method/mfa-validator';

@Component({
  selector: 'csi-doctor-form',
  templateUrl: './doctor-form.component.html',
  styleUrls: ['./doctor-form.component.css'],
  providers: [PersonNamePipe]
})
export class DoctorFormComponent implements OnInit {
  @Input() doctorFormGroup: DoctorFormGroup;
  @Input() showRequiredFields = false;
  @Input() hideTitle = false;
  @Input() showEmail = false;
  @Input() showInCard = true;
  @Input() showStaffType = false;

  @Input() showPasswordChange = true;

  public dummyPasswordFormControl: FormControl = new FormControl({
    value: '********',
    disabled: true
  });
  public combinedCountryCodePhoneNumberControl: FormControl = new FormControl({
    value: 'N/A',
    disabled: true
  });

  public readonly NameFormat = NameFormat;
  public allNameFormats = Object.keys(NameFormat);

  constructor(
    @Inject(SUPPORT_EMAIL_ACCESSOR) public supportEmail: string,
    private dialog: MatDialog,
    public staffService: StaffService,
    public personNamePipe: PersonNamePipe
  ) {}

  ngOnInit() {
    this.initializeNameFormat();

    this.doctorFormGroup.setValidators([
      phoneNumberAndCountryCodeValidator('phone', 'countryCode'),
      mfaValidator
    ]);
    this.doctorFormGroup.updateValueAndValidity();

    if (this.doctorFormGroup.controls.phone.value) {
      this.combinedCountryCodePhoneNumberControl.setValue(
        this.doctorFormGroup.controls.countryCode.value + this.doctorFormGroup.controls.phone.value
      );
    }
  }

  initializeNameFormat() {
    if (!this.doctorFormGroup.controls.displayedNameFormat.value) {
      if (this.staffService.isDoctor || this.staffService.isClinicOwner) {
        this.doctorFormGroup.controls.displayedNameFormat.setValue(
          NameFormat.TitleWithAbbreviatedFirstAndFullLastName
        );
      } else {
        this.doctorFormGroup.controls.displayedNameFormat.setValue(NameFormat.Fullname);
      }
    }
    if (this.staffService.isTech || this.staffService.isReceptionist) {
      this.doctorFormGroup.controls.displayedNameFormat.disable();
    }
  }

  onChangePassword() {
    this.dialog.open(ChangePasswordModalComponent);
  }
}
