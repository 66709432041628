import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Inject,
  Injectable,
  Injector,
  LOCALE_ID,
  NgZone
} from '@angular/core';
import { Logger } from 'aws-amplify';
import { Observable, iif, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Clinic, Doctor } from 'src/API';
import { ClinicService } from '../core/api/clinic.service';
import { Html2PdfService } from '../core/api/html-2-pdf.service';
import { PatientService } from '../core/api/patient.service';
import { ClinicSetupService } from '../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { QuestionnairePdfExportComponent } from './questionnaire-pdf-export/questionnaire-pdf-export.component';

@Injectable({
  providedIn: 'root'
})
export class QuestionnairePdfExportService {
  private pdfExportComponentPortal: ComponentPortal<QuestionnairePdfExportComponent>;
  private debugWindow: Window;
  private bodyPortalHost: DomPortalHost;
  private logger: Logger = new Logger('PDFExportService');
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private html2PdfService: Html2PdfService,
    private clinicService: ClinicService,
    private clinicSetupService: ClinicSetupService,
    public patientService: PatientService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private ngZone: NgZone,
    private datePipe: DatePipe
  ) {
    this.setupPortals();
  }

  public downloadQuestionnaireAsPDF(
    questionnaireKey: string,
    questionnaireResponseData,
    debug: boolean = false
  ): Observable<boolean> {
    const filename = `${questionnaireKey}-${this.getDateForPDFFilename()}.pdf`;
    return this.patientService.getPatient(this.patientService.patient.id).pipe(
      switchMap(patient =>
        this.getHtmlForQuestionnaire(
          patient,
          questionnaireKey,
          questionnaireResponseData,
          this.clinicSetupService.clinic,
          false,
          false
        )
      ),
      switchMap(html => this.html2PdfService.saveHtmlAsPdf(html, filename))
    );
  }

  public getHtmlForQuestionnaire(
    patient,
    questionnaireKey: string,
    questionnaireResponseData: any,
    clinic: Clinic | Doctor['clinic'],
    hideDataForFaxing: boolean = false,
    debug: boolean
  ): Observable<string> {
    if (debug) {
      // Open the debug window here since this spot is the only spot that won't trigger browser
      // popup blocking if this method is called from a click handler.
      this.openDebugWindow();
    }
    return iif(() => !!clinic, this.clinicService.getClinicLogo(clinic.id), of(null)).pipe(
      switchMap(clinicLogo => {
        const componentRef = this.bodyPortalHost.attach(this.pdfExportComponentPortal);
        (componentRef.location.nativeElement as HTMLElement).style.display = 'none';

        componentRef.instance.data = { patient, questionnaireResponseData, questionnaireKey };
        componentRef.instance.clinic = clinic;
        componentRef.instance.clinicLogo = clinicLogo;

        this.logger.debug('here is my data', componentRef.instance.data);

        return (componentRef.instance.html as Observable<string>).pipe(
          tap(html => {
            this.bodyPortalHost.detach();

            if (debug) {
              this.writeHTMLToDebugWindow(html);
            }
          })
        );
      })
    );
  }

  private getDateForPDFFilename(): string {
    return this.datePipe.transform(new Date(), 'yyyy-MM-ddThh-mm-ss');
  }
  private openDebugWindow() {
    this.debugWindow = window.open('', '_blank');
    this.debugWindow.document.title = 'CSI PDF Debug Loading...';
  }
  private writeHTMLToDebugWindow(html: string) {
    this.debugWindow.document.write(html);
    this.debugWindow.document.title = 'CSI PDF Debug';

    // For some reason the dry eye category component renders weird without this.
    // The 227px is the minimum but it can be set higher (lower values also cause
    // weird rendering). After 1 cycle we can clear the height (we do this
    // otherwise the body has no padding at the bottom).
    this.debugWindow.document.body.style.height = '227px';
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.debugWindow.document.body.style.height = '';
      });
    });
  }

  private setupPortals() {
    this.pdfExportComponentPortal = new ComponentPortal(QuestionnairePdfExportComponent);
    this.bodyPortalHost = new DomPortalHost(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
  }
}
