import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { Doctor, NameFormat, StaffType } from 'src/API';
import { ClinicService } from '../../core/api/clinic.service';
import { ClinicSetupService } from './../../logged-in-navbar/clinic-setup-modal/clinic-setup.service';

@Component({
  selector: 'csi-choose-doctor-from-clinic',
  templateUrl: './choose-doctor-from-clinic.component.html',
  styleUrls: ['./choose-doctor-from-clinic.component.css']
})
export class ChooseDoctorFromClinicComponent implements OnInit, OnDestroy {
  @Input() public doctorFormId: FormControl;
  @Input() public prependedToCurrentDoctor: string;

  public clinicDoctors$: BehaviorSubject<Partial<Doctor>[]> = new BehaviorSubject([]);

  public currentSelectedDoctor: Partial<Doctor> = null;

  public readonly NameFormat = NameFormat;

  public isLoading = true;

  constructor(
    private clinicService: ClinicService,
    private clinicSetupService: ClinicSetupService
  ) {}

  ngOnDestroy() {}

  ngOnInit() {
    this.clinicService
      .getClinicStaff(this.clinicSetupService.clinicId)
      .pipe(untilDestroyed(this))
      .subscribe(staffList => {
        this.isLoading = false;
        this.clinicDoctors$.next(
          staffList.filter(
            staff =>
              staff.id === this.clinicSetupService.clinicOwnerId ||
              staff.staffType === StaffType.Doctor
          )
        );
        this.currentSelectedDoctor = this.getSelectedDoctor();
      });

    this.doctorFormId.valueChanges.subscribe(
      () => (this.currentSelectedDoctor = this.getSelectedDoctor())
    );
  }

  public findDoctor(doctorId: string): Partial<Doctor> {
    return this.clinicDoctors$.value.find(clinicDoctor => clinicDoctor.id === doctorId) as Doctor;
  }

  public getSelectedDoctor(): Partial<Doctor> {
    const currentlySelectedDoctor = this.findDoctor(this.doctorFormId.value);

    return currentlySelectedDoctor;
  }
}
