import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject, zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AssessmentService } from 'src/app/core/api/assessment.service';
import { PatientService } from 'src/app/core/api/patient.service';
import { AssessmentFormService } from 'src/app/encounters/assessment-form.service';
import { AssessmentRouteData } from 'src/app/encounters/assessment/assessment.component';
import { medicalHistoryCustomAutopopulateConfig } from 'src/app/encounters/encounter-linking/medical-history-linking';
import { MedicalHistoryLayoutService } from 'src/app/logged-in-navbar/clinic-setup-modal/layouts/medical-history-layout/medical-history-layout.service';
import { AutopopulateService } from 'src/app/shared/autopopulate/autopopulate.service';
import { DryEyeMedicalHistoryFormGroup } from '../dry-eye-medical-history-form.model';
import { Assessment, Drug, IntakeForm, QuestionnaireRequest } from './../../../../../../API';
import { PatientFileService } from './../../../../../patients/patient/patient-file/patient-file.service';
import { DerfsService } from './../../../../../questionnaires/derfs/derfs.service';
import { DrugbankLookupService } from './../../../../medication-lookup/drugbank-lookup.service';
import { SchemaService } from './../../../../symptoms/services/schema/schema.service';

@Component({
  selector: 'csi-medical-history-fields',
  templateUrl: './medical-history-fields.component.html',
  styleUrls: ['./medical-history-fields.component.css']
})
export class MedicalHistoryFieldsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('datePickerInput', { static: false }) datePickerInputRef: MatInput;
  @Input() formGroup: DryEyeMedicalHistoryFormGroup;
  @Input() medicalHistoryApplicableFields: string[];
  @Input() assessmentMethod: string;
  @Input() assessmentRouteData: AssessmentRouteData;
  @Input() onPatientFileSaved$: Subject<Object>;
  @Input() onQuestionnaireLinked$: Subject<QuestionnaireRequest[]>;
  @Input() isMedicationSection: boolean;
  @Input() questionnaireMode: boolean;

  readonly disabledFieldsDisclaimer = `The disabled fields are non-modifiable data. This information is populated based on the
   response by the patients when they fill out CDERFS/DERFS and Intake Form. You can change
   the information through the patient profile.`;

  readonly eyeTearingQuantityBottomLabels = [
    'N/A',
    'NO TEARS AT ALL',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'FALLS ON CHEEK ALL THE TIME'
  ];

  readonly cityHumidityLevelBottomLabels = [
    'VERY DRY',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'VERY HUMID'
  ];

  readonly cityPollutionLevelBottomLabels = [
    'NO POLLUTION',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'VERY POLLUTED'
  ];

  readonly sensitivityToLightBottomLabels = ['NONE', '', '', '', '', '', '', '', '', '', 'SEVERE'];
  readonly sensitivityToWindBottomLabels = ['NONE', '', '', '', '', '', '', '', '', '', 'SEVERE'];
  readonly impactOfSymptomsOnMoodBottomLabels = [
    'NONE',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'SEVERE'
  ];
  readonly impactOfSymptomsOnLifeBottomLabels = [
    'NONE',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'SEVERE'
  ];

  readonly eyeItchinessQuantityBottomLabels = [
    'NONE',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'SEVERE'
  ];

  readonly frequencyOfSymptomsBottomLabels = [
    'N/A',
    'RARELY',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'ALL THE TIME'
  ];

  readonly severityOfSymptomsBottomLabels = [
    'N/A',
    'VERY MILD',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'VERY SEVERE'
  ];

  readonly qualityBottomLabels = ['N/A', 'POOR', '', '', '', '', '', '', '', '', 'GOOD'];

  readonly stressLevelBottomLabels = ['N/A', 'LOW', '', '', '', '', '', '', '', '', 'HIGH'];

  public id: string;
  public nonModifiableData;
  public disabledNonModifiableFields = new Set();
  public assessment: Assessment;
  public lastCompletedDerfs = null;
  public drugsNotCopied: string[] = [];
  public drugsNotFound: string[] = [];

  public ngSelectMedicalHistoryFieldKeys = {
    tearingQuality: 'tearingQuality',
    screenTime: 'screenTime',
    bothersomeEye: 'bothersomeEye',
    symptomSeverityOccurrence: 'symptomSeverityOccurrence',
    sleepingPosition: 'sleepingPosition',
    eyeRubbingFrequency: 'eyeRubbingFrequency',
    sunTanExposureFrequency: 'sunTanExposureFrequency',
    lastSunExposure: 'lastSunExposure',
    eyeColor: 'eyeColor',
    longSunExposure: 'longSunExposure',
    naturalHairColor: 'naturalHairColor',
    darkerAfterSunExposure: 'darkerAfterSunExposure',
    skinColor: 'skinColor',
    faceSunSensitivity: 'faceSunSensitivity',
    freckles: 'freckles',
    tanIntensity: 'tanIntensity'
  };

  medicationMap = {};

  medicationsGenerating = false;

  public medicalHistoryCustomAutopopulateConfig = medicalHistoryCustomAutopopulateConfig;

  constructor(
    public schemaService: SchemaService,
    public medicalHistoryLayoutService: MedicalHistoryLayoutService,
    public patientService: PatientService,
    public assessmentFormService: AssessmentFormService,
    public derfsService: DerfsService,
    public patientFileService: PatientFileService,
    private assessmentService: AssessmentService,
    private drugbankLookupService: DrugbankLookupService,
    private changeDetectorRef: ChangeDetectorRef,
    private autopopulateService: AutopopulateService
  ) {
    this.updateAssessmentId();
  }
  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.assessment = this.assessmentRouteData.assessment;

    if (this.isMedicationSection) {
      return;
    }
    this.onPatientFileSaved$.pipe(untilDestroyed(this)).subscribe(patientFileSaveData => {
      if (patientFileSaveData) {
        this.updateAssessmentId();
        this.assessmentService.getAssessment(this.id).subscribe(updatedAssessment => {
          if (this.id === 'new' || this.isToday(new Date(updatedAssessment.createdAt))) {
            this.populateMedicalHistoryWithNonModifiableData(patientFileSaveData);
            this.disableNonModfiableControls();
          }
        });
      }
    });

    this.onQuestionnaireLinked$.subscribe(linkedQuestionnaires => {
      this.patchMedicalHistoryWithLinkedQuestionnairesData(linkedQuestionnaires);
    });

    setTimeout(() => {
      if (this.id === 'new') {
        this.patientService.getPatientQuestionnaire().subscribe(() => {
          this.lastCompletedDerfs = this.getLastCompletedDerfs();
          this.autoFillMedicalHistory();
        });
        this.populateMedicalHistoryWithIntakeForm(this.patientService.intakeForms[0]);
      } else if (this.assessment && this.isToday(new Date(this.assessment.createdAt))) {
        // this.patientService.getPatient(this.patientService.patient.id).subscribe(patient => {
        //   this.populateMedicalHistoryWithNonModifiableData(JSON.parse(patient.nonModifiableData));
        // });
        this.populateMedicalHistoryWithNonModifiableData(
          JSON.parse(this.patientService.patient.nonModifiableData)
        );
      }
      if (this.datePickerInputRef) {
        this.datePickerInputRef.value = new Date().toISOString();
      }

      this.disableNonModfiableControls();

      // Disable this field for doctor side
      this.formGroup.controls.patientEnteredMedicationsFreeText.disable();
    }, 1000);
  }

  public updateAssessmentId() {
    const pathname = location.pathname;
    this.id = pathname.substring(pathname.indexOf(':') + 1, pathname.length - 1);
  }

  public isToday(date: Date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  public autoFillMedicalHistory() {
    this.applyFunctionOnDerfsSchema(this.mapDerfsToMedicalHistory.bind(this));
    this.patientService.getPatient(this.patientService.patient.id).subscribe(patient => {
      this.populateMedicalHistoryWithNonModifiableData(JSON.parse(patient.nonModifiableData));
    });
  }

  public populateMedicalHistoryWithNonModifiableData(nonModifiableData: any) {
    if (!nonModifiableData) return;

    this.formGroup.controls['previousOcularSurgeryFreeText'].patchValue('');
    Object.keys(nonModifiableData).forEach(key => {
      if (this.derfsService.flattenedDerfsSchema[key]) {
        if (
          this.derfsService.flattenedDerfsSchema[key].mapsTo &&
          this.derfsService.flattenedDerfsSchema[key].mapsTo[0] === 'previousOcularSurgeryFreeText'
        ) {
          this.formGroup.controls['previousOcularSurgeryFreeText'].setValue(
            this.patientFileService.transformPastOcularHistoryValue(
              this.formGroup.controls['previousOcularSurgeryFreeText'].value,
              this.derfsService.flattenedDerfsSchema[key].description,
              nonModifiableData[key]
            )
          );
        } else if (this.formGroup['controls'][key]) {
          this.mapDerfsToMedicalHistory(
            this.derfsService.flattenedDerfsSchema[key],
            nonModifiableData[key]
          );
        }
        if (
          // Hacky way to deal with toggleable fields
          this.derfsService.flattenedDerfsSchema[key].mapsTo &&
          this.derfsService.flattenedDerfsSchema[key].mapsTo[1]
        ) {
          this.formGroup['controls'][
            this.derfsService.flattenedDerfsSchema[key].mapsTo[1]
          ].patchValue(nonModifiableData[key + 'Value']);
        }
      } else {
        // console.log('FormControl key not found in flattened derfs schema: ', key);
      }
    });
    this.formGroup.markAsDirty();
  }

  populateMedicalHistoryWithIntakeForm(intakeForm: IntakeForm) {
    if (!intakeForm) return;
    const { patientGeneralHealthQuestion } = JSON.parse(intakeForm.response);

    if (patientGeneralHealthQuestion.medications) {
      this.formGroup.controls.patientEnteredMedicationsFreeText.setValue(
        this.formGroup.controls.patientEnteredMedicationsFreeText.value +
          '\n' +
          'Intake Form: ' +
          '\n' +
          patientGeneralHealthQuestion.medications
      );
    }
  }

  public disableNonModfiableControls() {
    // this.applyFunctionOnDerfsSchema(this.disableControlIfNonModifiable.bind(this));
    Object.values(this.derfsService.flattenedDerfsSchema).forEach(controlSchemaData => {
      this.disableControlIfNonModifiable(controlSchemaData);
    });
  }

  public applyFunctionOnDerfsSchema(func: (schemaData, responseDataValue) => void) {
    if (!this.lastCompletedDerfs) return;
    const answers = JSON.parse(this.lastCompletedDerfs['response']['answers']);

    Object.keys(this.derfsService.derfsFormGroupSchema).forEach(questionGroup => {
      if (answers[questionGroup]) {
        Object.keys(this.derfsService.derfsFormGroupSchema[questionGroup].controls).forEach(key => {
          const questionGroupSchema = this.derfsService.derfsFormGroupSchema[questionGroup]
            .controls;
          if (questionGroupSchema[key].type === 'FormGroup') {
            Object.keys(questionGroupSchema[key].controls).forEach(childKey => {
              const controls = questionGroupSchema[key].controls;
              func(controls[childKey], answers[questionGroup][childKey]);
              // For checkboxes
              if (controls[childKey].type === 'SubFormGroup') {
                Object.keys(controls[childKey].controls).forEach(element => {
                  const childControls = controls[childKey].controls;
                  func(childControls[element], answers[questionGroup][element]);
                  func(childControls[element], answers[questionGroup][element + 'Value']);
                });
              }
            });
            if (!questionGroupSchema[key].noParentControl) {
              func(questionGroupSchema[key], answers[questionGroup][key]);
            }
          } else {
            func(questionGroupSchema[key], answers[questionGroup][key]);
          }
        });
      }
    });
  }

  public disableControlIfNonModifiable(schemaData: any) {
    if (schemaData.mapConditions === undefined) {
      return;
    }
    const mapConditionsLength = schemaData.mapConditions.length;
    for (let i = 0; i < mapConditionsLength; i++) {
      if (schemaData.nonModifiableConfig && schemaData.nonModifiableConfig.nonModifiable) {
        if (this.formGroup.controls[schemaData.mapsTo[i]]) {
          // this.formGroup.controls[schemaData.mapsTo[i]].disable();
          this.disabledNonModifiableFields.add(schemaData.mapsTo[i]);
          // console.log('Disabling: ', schemaData.mapsTo[i]);
        }
      }
    }
  }

  public mapDerfsToMedicalHistory(schemaData: any, responseDataValue: any) {
    if (schemaData.mapConditions === undefined) {
      return;
    }

    const mapConditionsLength = schemaData.mapConditions.length;
    for (let i = 0; i < mapConditionsLength; i++) {
      // this.disableControlIfNonModifiable(schemaData, i);
      if (this.mapConditionSatisfied(schemaData.mapConditions[i], responseDataValue)) {
        this.applyMapping(schemaData, i, responseDataValue);
        // console.log('Applying map', schemaData);
      } else {
        if (schemaData.defaultMapValue) {
          this.formGroup.controls[schemaData.mapsTo[i]].setValue(schemaData.defaultMapValue[i]);
        }
      }
    }
  }

  // public disableControlIfNonModifiable(schemaData: any, mapIndex: number) {
  //   if (schemaData.nonModifiableConfig && schemaData.nonModifiableConfig.nonModifiable) {
  //     this.formGroup.controls[schemaData.mapsTo[mapIndex]].disable();
  //   }
  // }

  mapConditionSatisfied(mapCondition: any, val: any) {
    return (
      val !== undefined &&
      (val === mapCondition ||
        (typeof val === 'number' && val >= mapCondition) ||
        (mapCondition === '' && typeof val === 'string' && val.length >= 1))
    );
  }

  applyMapping(schemaData: any, mapConditionIndex: number, responseDataValue: any) {
    if (this.formGroup.controls[schemaData.mapsTo[mapConditionIndex]]) {
      if (schemaData.mapValues) {
        this.formGroup.controls[schemaData.mapsTo[mapConditionIndex]].setValue(
          schemaData.mapValues[mapConditionIndex]
        );
      } else if (schemaData.transformValue) {
        this.formGroup.controls[schemaData.mapsTo[mapConditionIndex]].setValue(
          schemaData.transformValue(
            responseDataValue,
            this.formGroup.controls[schemaData.mapsTo[mapConditionIndex]].value
          )
        );
      } else {
        this.formGroup.controls[schemaData.mapsTo[mapConditionIndex]].setValue(responseDataValue);
      }
    }
  }

  public patchMedicalHistoryWithLinkedQuestionnairesData(
    linkedQuestionnaires: QuestionnaireRequest[]
  ) {
    let latestDerfsQuestionnaire = null;
    if (!linkedQuestionnaires) return;
    linkedQuestionnaires.forEach(questionnaire => {
      if (questionnaire.type === 'DERFS' || questionnaire.type === 'CDERFS') {
        if (latestDerfsQuestionnaire) {
          if (
            new Date(latestDerfsQuestionnaire.completedAt).getTime() <
            new Date(questionnaire.completedAt).getTime()
          ) {
            latestDerfsQuestionnaire = questionnaire;
          }
        } else {
          latestDerfsQuestionnaire = questionnaire;
        }
      } else if (questionnaire.type === 'CUSTOM' || questionnaire.type === 'FITZPATRICK') {
        this.populateMedicalHistoryWithCustomQuestionnaireData(questionnaire);
      }
    });
    if (latestDerfsQuestionnaire) {
      this.formGroup.patchValue(this.derfsService.getDerfDataToCopy(latestDerfsQuestionnaire));
    }
    this.formGroup.markAsDirty();
  }

  populateMedicalHistoryWithCustomQuestionnaireData(questionnaire: QuestionnaireRequest) {
    this.autopopulateService.populateFormGroup(
      this.formGroup,
      this.medicalHistoryCustomAutopopulateConfig,
      JSON.parse(questionnaire.response.answers)
    );
  }

  getLastCompletedDerfs() {
    const derfsQuestionnaires = this.patientService.questionnaires.filter(questionnaire => {
      return (
        (questionnaire.type === 'DERFS' || questionnaire.type === 'CDERFS') &&
        questionnaire.completedAt
      );
    });

    const mostRecentDate = new Date(
      Math.max.apply(
        null,
        derfsQuestionnaires.map(item => {
          return item.completedAt && new Date(item.completedAt);
        })
      )
    );

    const mostRecentObject = derfsQuestionnaires.filter(item => {
      if (!item.completedAt) return;
      const d = new Date(item.completedAt);
      return d.getTime() === mostRecentDate.getTime();
    })[0];
    return mostRecentObject;
  }

  public formatThumbLabel(value: number | null): number {
    if (!value) {
      return 0;
    }

    return Math.round(value / 10);
  }

  public closeDatePicker(event: any, dp: any, input: any, control: FormControl) {
    const formattedDate = event
      .toISOString()
      .split('-')
      .join('/')
      .substr(0, 7);
    control.setValue(formattedDate);

    input.value = formattedDate;

    dp.close();
  }

  getMedicationToVerifiedField() {
    this.medicationsGenerating = true;

    const medicationsString: string =
      this.formGroup.controls.patientEnteredMedicationsFreeText.value || '';
    const medicationsArray: string[] = medicationsString.split(/\s*,\s*/).filter(Boolean);
    zip(
      ...medicationsArray.map(medication =>
        this.drugbankLookupService
          .searchMedication({
            query: medication
          })
          .pipe(
            tap((result: Drug[]) => {
              this.medicationMap = {
                ...this.medicationMap,
                [medication]: (result || []).slice(0, 4)
              };
            })
          )
      )
    ).subscribe(res => {
      this.medicationMap = { ...this.medicationMap };
      this.medicationsGenerating = false;

      this.changeDetectorRef.markForCheck();
    });
  }

  copyMedicationToFormGroup(medication: Drug) {
    if (
      !(this.formGroup.controls.medications.value as Drug[]).find(
        currentMedication => medication.id === currentMedication.id
      )
    ) {
      this.formGroup.controls.medications.setValue([
        ...(this.formGroup.controls.medications.value || []),
        medication
      ]);
    }
  }
}
