import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { StaffService } from 'src/app/core/api/staff.service';
import { Doctor, Gender, NameFormat } from '../../../API';
import { Patient } from '../../core/api/patient.service';

@Pipe({
  name: 'personName'
})
export class PersonNamePipe implements PipeTransform {
  private titleCasePipe: TitleCasePipe;

  constructor(private staffService: StaffService) {
    this.titleCasePipe = new TitleCasePipe();
  }

  transform(
    user: Doctor | Patient,
    format: NameFormat,
    skipAutoCapitalization: boolean = false
  ): string | null {
    if (!user) return null;

    let result: string | null = null;

    if (user.__typename === 'Patient') {
      const name = this.getFullName(user);
      if (format !== NameFormat.Fullname) {
        result = this.getPatientTitle(user.gender) + name;
      } else {
        result = name;
      }
    }

    if (user.__typename === 'Doctor') {
      result = this.getDoctorName(user, format);
    }

    result = (result && result.trim()) || this.getFullName(user) || null;

    return skipAutoCapitalization ? result : this.titleCasePipe.transform(result);
  }

  private getPatientTitle(gender?: Gender): string {
    if (gender === Gender.male) return 'Mr. ';
    if (gender === Gender.female) return 'Ms. ';
    return '';
  }

  private getDoctorName(doctor: Doctor, format: NameFormat): string {
    switch (format) {
      case NameFormat.TitleWithFullName:
        return this.getTitleWithFullName(doctor);
      case NameFormat.TitleWithFirstName:
        return this.getTitleWithFirstName(doctor);
      case NameFormat.TitleWithLastName:
        return this.getTitleWithLastName(doctor);
      case NameFormat.TitleWithAbbreviatedLastName:
        return this.getTitleWithAbbreviatedLastName(doctor);
      case NameFormat.TitleWithAbbreviatedFirstAndFullLastName:
        return this.getTitleWithAbbreviatedFirstAndFullLastName(doctor);
      case NameFormat.TitleWithFullFirstNameAndAbbreviatedLastName:
        return this.getTitleWithFullFirstNameAndAbbreviatedLastName(doctor);
      default:
        return this.getFullName(doctor);
    }
  }

  private getFullName(user: Doctor | Patient): string {
    const firstName = user.firstName || '';
    const lastName = user.lastName || '';
    return `${firstName} ${lastName}`.trim();
  }

  private getTitleWithFullName(user: Doctor): string {
    return `Dr. ${this.getFullName(user)}`;
  }

  private getTitleWithFirstName(user: Doctor): string {
    return `Dr. ${user.firstName}`;
  }

  private getTitleWithLastName(user: Doctor): string {
    return `Dr. ${user.lastName}`;
  }

  private getTitleWithAbbreviatedLastName(user: Doctor): string {
    return `Dr. ${user.lastName.charAt(0)}.`;
  }

  private getTitleWithAbbreviatedFirstAndFullLastName(user: Doctor): string {
    return `Dr. ${user.firstName.charAt(0)}. ${user.lastName}`;
  }

  private getTitleWithFullFirstNameAndAbbreviatedLastName(user: Doctor): string {
    return `Dr. ${user.firstName} ${user.lastName.charAt(0)}.`;
  }
}
