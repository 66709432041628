import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface InsertTextItemConfig {
  label: string;
  textToInsert: string;
}

export interface InsertTextConfig {
  items: InsertTextItemConfig[];
}

@Component({
  selector: 'csi-custom-notification-builder',
  templateUrl: './custom-notification-builder.component.html',
  styleUrls: ['./custom-notification-builder.component.css']
})
export class CustomNotificationBuilderComponent implements OnInit {
  @ViewChild('textarea', { static: false }) textarea!: ElementRef<HTMLTextAreaElement>;

  @Input() control!: FormControl;
  @Input() config: InsertTextConfig;

  constructor() {}

  ngOnInit() {
    if (!this.control) {
      console.error('CustomNotificationBuilderComponent: control is undefined or null.');
      return;
    }
  }

  insertTextAtCaret(text: string) {
    if (!this.control) {
      console.error(
        'CustomNotificationBuilderComponent: Cannot insert text into an undefined control.'
      );
      return;
    }

    const textarea = this.textarea.nativeElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const value = textarea.value;

    this.control.patchValue(value.substring(0, start) + text + value.substring(end));
    this.control.markAsDirty();
    textarea.selectionStart = textarea.selectionEnd = start + text.length;
    textarea.focus();
  }

  ngAfterViewInit() {}
}