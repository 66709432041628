import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { StaffService } from 'src/app/core/api/staff.service';
import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';

@Component({
  selector: 'csi-change-clinic-modal',
  templateUrl: './change-clinic-modal.component.html',
  styleUrls: ['./change-clinic-modal.component.css']
})
export class ChangeClinicModalComponent implements OnInit {
  public selectedClinicId = new FormControl();
  touched = false;

  constructor(
    public staffService: StaffService,
    public confirmModalService: ConfirmModalService,
    private loadingSpinnerService: LoadingSpinnerService
  ) {}

  ngOnInit() {
    this.selectedClinicId.valueChanges.subscribe(() => {
      this.touched = true;
    });
  }

  static open(dialog: MatDialog): MatDialogRef<ChangeClinicModalComponent> {
    return dialog.open(ChangeClinicModalComponent, {
      minWidth: '19.1rem'
    });
  }

  changeClinic() {
    const ownedClinics = JSON.parse(this.staffService.staff.ownedClinics || '{}');

    const clinicName = ownedClinics[this.selectedClinicId.value];

    this.confirmModalService
      .show(
        `${clinicName ? 'Change Clinic to ' + clinicName : 'Create New Clinic'}`,
        `Are you sure you want to ${
          clinicName ? 'change clinic to ' + clinicName : 'create new clinic'
        }?`,
        `${clinicName ? 'Change Clinic' : 'Create New Clinic'}`,
        'Cancel'
      )
      .subscribe(confirmed => {
        if (confirmed) {
          this.loadingSpinnerService.show();

          if (this.staffService.staff.clinic) {
            ownedClinics[this.staffService.staff.clinic.id] = this.staffService.staff.clinic.name;
          }
          this.staffService
            .updateDoctor({
              id: this.staffService.staff.id,
              ownedClinics: JSON.stringify(ownedClinics),
              doctorClinicId: this.selectedClinicId.value || null
            })
            .subscribe(() => window.location.reload());
        }
      });
  }
}
