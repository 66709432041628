import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ClinicSetup } from 'src/API';
import { CreateClinicSetupInput, UpdateClinicSetupInput } from './../../../API';
import { AbstractControlsMap } from './../../shared/dynamic-form-group/dynamic-form-group';
import { notificationMap } from './customizable-welcome-notification/notification-map';
import { Layout } from './layouts/layouts.service';
import { defaultTextFavoriteMap } from './text-favorite/text-favorite-model';
import { defaultTreatmentTemplateMap } from './treatment-template/treatment-template-model';

interface ClinicSetupFormControls extends AbstractControlsMap {
  id: FormControl;
  signsLayout: FormControl;
  textFavorites: FormControl;
  treatmentTemplates: FormControl;
  medicalHistoryLayout: FormControl;
  layouts: FormControl;
  defaultQuestionnaireLanguage: FormControl;
  defaultPdfLanguage: FormControl;
  temperatureUnit: FormControl;
  questionnaireExpiration: FormControl;
  welcomeMessageConfig: FormGroup;
  emailNotificationsConfig: EmailNotificationsConfigFormGroup;
  hiddenTextFavorites: FormControl;
  hiddenTreatmentTemplates: FormControl;
  textFavoriteConfig: FormControl;
  questionnaireScoreConfig: FormControl;
  questionnaireNotificationsConfig: FormGroup;
}

export class EmailNotificationsConfigFormGroup extends FormGroup {
  constructor() {
    super({
      notificationRecipients: new FormControl(),
      enabled: new FormControl(true)
    });
  }
}

export class WelcomeMessageConfigFormGroup extends FormGroup {
  constructor() {
    super({
      enabled: new FormControl(false),
      message: new FormControl('')
    });
  }
}

export class QuestionnaireNotificationsConfigFormGroup extends FormGroup {
  constructor() {
    super({
      en: new FormControl(),
      es: new FormControl(),
      fr: new FormControl()
    });
  }
}

export class ClinicSetupFormGroup extends FormGroup implements OnInit {
  private readonly DEFAULT_DAYS = 14;

  controls: ClinicSetupFormControls;

  constructor() {
    super({
      id: new FormControl(),
      textFavorites: new FormControl(),
      treatmentTemplates: new FormControl(),
      layouts: new FormControl(),
      defaultQuestionnaireLanguage: new FormControl(),
      defaultPdfLanguage: new FormControl(),
      temperatureUnit: new FormControl(),
      questionnaireExpiry: new FormControl(),
      // Use the notificationMap for the welcomeMessageConfig FormGroup
      welcomeMessageConfig: new WelcomeMessageConfigFormGroup(),
      emailNotificationsConfig: new EmailNotificationsConfigFormGroup(),
      hiddenTextFavorites: new FormControl(),
      hiddenTreatmentTemplates: new FormControl(),
      textFavoriteConfig: new FormControl(),
      questionnaireScoreConfig: new FormControl(),
      questionnaireNotificationsConfig: new QuestionnaireNotificationsConfigFormGroup()
    });
  }

  ngOnInit() {}

  patchValueFromClinicSetup(clinicSetup: ClinicSetup, doctorEmail: string) {
    if (clinicSetup) {
      const notificationConfig = JSON.parse(clinicSetup.emailNotificationsConfig || '{}');
      if (!notificationConfig.notificationRecipients) {
        notificationConfig.notificationRecipients = [doctorEmail];
      }

      const defaultTextFavoriteConfig = JSON.stringify({
        impressions: [],
        recommendations: [],
        goals: []
      });

      this.patchValue({
        ...clinicSetup,
        textFavoriteConfig: JSON.parse(clinicSetup.textFavoriteConfig || defaultTextFavoriteConfig),
        hiddenTextFavorites:
          clinicSetup.hiddenTextFavorites ||
          Object.keys(defaultTextFavoriteMap).filter(
            textFavoriteKey => !defaultTextFavoriteMap[textFavoriteKey].showByDefault
          ),
        hiddenTreatmentTemplates:
          clinicSetup.hiddenTreatmentTemplates ||
          Object.keys(defaultTreatmentTemplateMap).filter(
            treatmentTemplateKey => !defaultTreatmentTemplateMap[treatmentTemplateKey].showByDefault
          ),
        questionnaireScoreConfig: JSON.parse(clinicSetup.questionnaireScoreConfig || '{}'),
        welcomeMessageConfig: JSON.parse(
          clinicSetup.welcomeMessageConfig || JSON.stringify(notificationMap.welcomeMessage.default)
        ),
        questionnaireNotificationsConfig: JSON.parse(
          clinicSetup.questionnaireNotificationsConfig ||
            JSON.stringify(notificationMap.questionnaireMessage.default)
        ),
        emailNotificationsConfig: {
          enabled: notificationConfig.enabled || false,
          notificationRecipients: notificationConfig.notificationRecipients || []
        }
      });
    }
  }

  updateClinicSetupInput(): UpdateClinicSetupInput {
    const {
      id,
      layouts,
      defaultPdfLanguage,
      defaultQuestionnaireLanguage,
      temperatureUnit,
      questionnaireExpiry,
      welcomeMessageConfig,
      emailNotificationsConfig,
      hiddenTextFavorites,
      hiddenTreatmentTemplates,
      textFavoriteConfig,
      questionnaireNotificationsConfig
    } = this.value;
    const updateInput = {
      id,
      defaultPdfLanguage,
      defaultQuestionnaireLanguage,
      temperatureUnit,
      questionnaireExpiry: questionnaireExpiry || this.DEFAULT_DAYS,
      welcomeMessageConfig: JSON.stringify(welcomeMessageConfig),
      emailNotificationsConfig: JSON.stringify(emailNotificationsConfig),
      hiddenTextFavorites: [...new Set(hiddenTextFavorites)] as string[],
      hiddenTreatmentTemplates: [...new Set(hiddenTreatmentTemplates)] as string[],
      textFavoriteConfig: JSON.stringify(textFavoriteConfig),
      questionnaireNotificationsConfig: JSON.stringify(
        this.controls.questionnaireNotificationsConfig.value
      )
    };
    if (this.controls.layouts.dirty) {
      updateInput['layouts'] = (layouts as Layout[]).map(layout => ({
        ...layout,
        symptomSettings: JSON.stringify(layout.symptomSettings)
      }));
    }
    return updateInput;
  }

  getCreateClinicSetupInput(clinicSetupClinicId: string): CreateClinicSetupInput {
    const createClinicSetupInput: CreateClinicSetupInput = { clinicSetupClinicId };

    return createClinicSetupInput;
  }
}
